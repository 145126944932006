import * as React from "react";
import Card from "@mui/material/Card";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import GradientBorder from "examples/GradientBorder";

// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import borders from "assets/theme/base/borders";
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import "app.css";
import { Password, Upload } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import { API_Host } from "Api_utils";
import { deleteQuizQuestion, updateQuiz } from "../../redux/slices/QuizSlice";
import Swal from "sweetalert2";

import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import DeleteIcon from "@mui/icons-material/Delete";

function UpdateQuiz() {
  const { id } = useParams();

  const dispatch = useDispatch();
  const history = useHistory();

  // validation state
  const [quizerror, setQuizError] = useState({});

  const quizData = useSelector((state) => state.quiz.quiz);
  const studentQuizData = quizData.find((quizData) => quizData.id === id);
  console.log(quizData);

  const [quizUpdateData, setQuizUpdateData] = useState({
    topic: studentQuizData ? studentQuizData.topic : "",
    // quizType: studentQuizData ? studentQuizData.quizType : "",
    questions: studentQuizData
      ? studentQuizData.questions
      : [{ question: "", options: [""], correctAnswer: "" }],
    // correctAnswer: studentQuizData ? studentQuizData.correctAnswer : "",
    // options: studentQuizData ? studentQuizData.options : [""],
    //  fileData:null
    // fileData: studentQuizData ? studentQuizData.fileData : null,
  });
  console.log(quizUpdateData);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const questionsPerPage = 6; // Number of questions per page

  // Calculate the index of the questions to display based on the current page
  const indexOfLastQuestion = currentPage * questionsPerPage;
  const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
  const currentQuestions = quizUpdateData.questions.slice(
    indexOfFirstQuestion,
    indexOfLastQuestion
  );

  // Function to handle changing the page
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const totalPages = Math.ceil(quizUpdateData.questions.length / questionsPerPage);

  // get call in rewardtype
  const [rewardType, setRewardType] = useState([]);
  React.useEffect(() => {
    const fetchRewardTypes = async () => {
      try {
        const response = await axios.get(`${API_Host}/get-rewards`);
        setRewardType(response.data.data.map((reward) => reward.rewardType));
      } catch (error) {
        console.error("Error fetching reward types:", error);
      }
    };

    fetchRewardTypes();
  }, []);

  const handleOptionChange = (event, questionIndex, optionIndex) => {
    const updatedQuestions = quizUpdateData.questions.map((question, index) => {
      if (index === questionIndex) {
        const updatedOptions = question.options.map((option, i) => {
          return i === optionIndex ? event.target.value : option; // Update the specific option
        });
        return { ...question, options: updatedOptions }; // Return the updated question
      }
      return question; // Return other questions unchanged
    });

    setQuizUpdateData((prevData) => ({
      ...prevData,
      questions: updatedQuestions,
    }));
  };

  const handleQuestionChange = (event, questionIndex) => {
    const updatedQuestions = quizUpdateData.questions.map((question, index) => {
      if (index === questionIndex) {
        return { ...question, question: event.target.value }; // Update the specific question
      }
      return question; // Return other questions unchanged
    });

    setQuizUpdateData((prevData) => ({
      ...prevData,
      questions: updatedQuestions,
    }));
  };

  const handleCorrectAnswer = (e, questionIndex) => {
    const updatedQuestions = quizUpdateData.questions.map((question, index) => {
      if (index === questionIndex) {
        return { ...question, correctAnswer: e.target.value }; // Update the correct answer
      }
      return question; // Return other questions unchanged
    });

    setQuizUpdateData((prevData) => ({
      ...prevData,
      questions: updatedQuestions, // Update the questions state
    }));
  };

  // update call
  // const handleUpdateTask = async (e) => {
  //     e.preventDefault();

  //     const handleEditValidateQuiz =await  quizValidate()
  // if(!handleEditValidateQuiz){
  //   return
  // }

  //     // Create FormData object
  //     const formData = new FormData();
  //     formData.append('quizName', quizUpdateData.quizName);
  //     formData.append('quizType', quizUpdateData.quizType);
  //     formData.append('question',quizUpdateData.question);
  //     formData.append('correctAnswer',quizUpdateData.correctAnswer);

  //     // Append file only if it's selected
  //     if (quizUpdateData.fileData) {
  //         formData.append('file', quizUpdateData.fileData);
  //     }

  //     try {
  //         const response = await axios.put(`${API_Host}/edit-quiz/${id}`, formData, {
  //             headers: {
  //                 'Content-Type': 'multipart/form-data'
  //             }
  //         });
  //         dispatch(updateQuiz(response.data.data));
  //         console.log("quiz updated Successfully", response.data.data);
  //         Swal.fire({
  //           title: "Quiz Edited Successfully!",
  //           text: "Your Quiz has been Edited.",
  //           icon: "success",
  //           showClass: {
  //             popup: `
  //               animate__animated
  //               animate__fadeInUp
  //               animate__faster
  //             `
  //           },
  //           hideClass: {
  //             popup: `
  //               animate__animated
  //               animate__fadeOutDown
  //               animate__faster
  //             `
  //           }
  //         });
  //         history.push('/quiz');
  //     } catch (err) {
  //         console.error("Error updateing quiz:", err);
  //     }
  // };

  //
  const handleUpdateQuiz = async (e) => {
    e.preventDefault();

    // const handleEditValidateQuiz = await quizValidate();
    // if (!handleEditValidateQuiz) {
    //   return;
    // }

    axios
      .put(`${API_Host}/edit-quiz/${id}`, quizUpdateData)
      .then((res) => {
        dispatch(updateQuiz(res.data.data));
        console.log("quiz added successfully", res.data.data);

        Swal.fire({
          title: "Quiz Updated Successfully!",
          text: "Your quiz has been updated.",
          icon: "success",
          showClass: {
            popup: `
                      animate__animated
                      animate__fadeInUp
                      animate__faster
                    `,
          },
          hideClass: {
            popup: `
                      animate__animated
                      animate__fadeOutDown
                      animate__faster
                    `,
          },
        });
        history.push("/quiz");
      })
      .catch((err) => console.log(err));
  };

  const handleChange = (event) => {
    const { name, value, files, type } = event.target;
    const inputValue = type === "file" ? files[0] : event.target.value;

    setQuizUpdateData((prevFormData) => ({
      ...prevFormData,
      // [name]: files ? files[0] : value,
      [name]: type === "file" ? files[0] : inputValue,
    }));

    const validation = { ...quizerror };
    if (value.trim() === "") {
      // validation[name] =``;
    } else {
      delete validation[name];
    }
    setQuizError(validation);
  };

  const quizValidate = async () => {
    const validation = {};

    if (!quizUpdateData.topic) validation.topic = "TopicName is required";
    // if (!quizUpdateData.quizType) validation.quizType = "QuizType is required";
    if (!quizUpdateData.questions) validation.questions = "quizQuestion is required";
    if (!quizUpdateData.correctAnswer) validation.correctAnswer = "CorrectAnswer is required";
    // const emptyOptions = quizUpdateData.options.some(
    //   (option) => !option.text || option.text.trim() === ""
    // );
    // if (emptyOptions) validation.options = "All quiz options are required";
    //  if(!quizUpdateData.fileData) validation.fileData = "uploadfile is required"

    setQuizError(validation);
    return Object.keys(validation).length === 0;
  };

  // const handleOptionDelete = (indexToDelete) => {
  //   setQuizUpdateData((prevData) => ({
  //     ...prevData,
  //     options: prevData.options.filter((_, index) => index !== indexToDelete),
  //   }));
  // };

  // const handleDeleteQuestion = (questionId) => {
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: "Do you really want to delete this QuizQuestion? This action cannot be undone.",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#d33",
  //     cancelButtonColor: "#3085d6",
  //     confirmButtonText: "Yes, delete it!",
  //     cancelButtonText: "No, keep it",
  //   })
  //     .then((quizResult) => {
  //       if (quizResult.isConfirmed) {
  //         Swal.fire({
  //           title: "Deleted!",
  //           text: "Your Quiz Question has been deleted.",
  //           icon: "success",
  //         });

  //         axios.delete(`${API_Host}/delete-particular-question/` + questionId).then((res) => {
  //           dispatch(deleteQuizQuestion({ questionId }));
  //           console.log("quiz Question deleted successfully", res);
          
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const handleDeleteQuestion = (questionId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to delete this Quiz Question? This action cannot be undone.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((quizResult) => {
      if (quizResult.isConfirmed) {
        axios
          .delete(`${API_Host}/delete-particular-question/${questionId}`)
          .then((res) => {
            // Remove the question from the local state
            setQuizUpdateData((prevData) => ({
              ...prevData,
              questions: prevData.questions.filter((q) => q._id !== questionId),
            }));
  
            // Dispatch action to update the Redux store
            dispatch(deleteQuizQuestion({ questionId }));
  
            Swal.fire({
              title: "Deleted!",
              text: "Your Quiz Question has been deleted.",
              icon: "success",
            });
          })
          .catch((err) => {
            console.log("Error deleting quiz question:", err);
          });
      }
    });
  };
  

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Box sx={{ width: "100%" }} mt={10} mb={20}>
        <VuiBox component="form" role="form" onSubmit={handleUpdateQuiz}>
          {/* first col */}
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid xs={12} sm={12} md={12}>
              <VuiBox mb={1} ml={2}>
                <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
                  TopicName
                </VuiTypography>
                <GradientBorder
                  minWidth="100%"
                  padding="1px"
                  borderRadius={borders.borderRadius.lg}
                  backgroundImage={radialGradient(
                    palette.gradients.borderLight.main,
                    palette.gradients.borderLight.state,
                    palette.gradients.borderLight.angle
                  )}
                >
                  {/* <VuiInput type="text" placeholder="Enter Your Courses..." fontWeight="500"
            name='rewardType'
            value={rewardsData.rewardType}
            onChange={handleChange}
             /> */}

                  <VuiInput
                    type="text"
                    placeholder="Enter Topic Name..."
                    name="topic"
                    value={quizUpdateData.topic}
                    onChange={handleChange}
                    validation={!!quizerror.topic}
                    sx={({ typography: { size } }) => ({
                      fontSize: size.sm,
                    })}
                  />
                </GradientBorder>
                {quizerror.topic && (
                  <VuiTypography variant="caption" color="error" mt={1}>
                    {quizerror.topic}
                  </VuiTypography>
                )}
              </VuiBox>
            </Grid>
            {/* end */}

            {currentQuestions.map((q, questionIndex) => (
              <Grid item xs={12} sm={6} md={4} key={questionIndex}>
                <VuiBox mb={1} ml={2}>
                  <VuiTypography
                    component="label"
                    variant="button"
                    color="white"
                    fontWeight="medium"
                  >
                    Question {indexOfFirstQuestion + questionIndex + 1}
                  </VuiTypography>
                  <GradientBorder
                    minWidth="100%"
                    padding="1px"
                    borderRadius={borders.borderRadius.lg}
                    backgroundImage={radialGradient(
                      palette.gradients.borderLight.main,
                      palette.gradients.borderLight.state,
                      palette.gradients.borderLight.angle
                    )}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <VuiInput
                      multiline
                      rows={2}
                      type="text"
                      placeholder="Enter Quiz Question..."
                      name={`question-${questionIndex}`}
                      value={q.question}
                      onChange={(e) =>
                        handleQuestionChange(e, indexOfFirstQuestion + questionIndex)
                      }
                      validation={!!quizerror.question}
                      sx={({ typography: { size } }) => ({
                        fontSize: size.sm,
                      })}
                    />
                     <DeleteIcon
                        onClick={() => handleDeleteQuestion(q._id)}
                        sx={{ color: "red", cursor: "pointer" }}
                      ></DeleteIcon>
                  </GradientBorder>
                  {quizerror.question && (
                    <VuiTypography variant="caption" color="error" mt={1}>
                      {quizerror.question}
                    </VuiTypography>
                  )}

                  {/* Map through options for each question */}
                  {q.options.map((option, optionIndex) => (
                    <Box key={optionIndex} mt={1}>
                      <VuiTypography
                        component="label"
                        variant="button"
                        color="white"
                        fontWeight="medium"
                      >
                        Option {optionIndex + 1}
                      </VuiTypography>
                      <GradientBorder
                        minWidth="100%"
                        padding="1px"
                        borderRadius={borders.borderRadius.lg}
                        backgroundImage={radialGradient(
                          palette.gradients.borderLight.main,
                          palette.gradients.borderLight.state,
                          palette.gradients.borderLight.angle
                        )}
                       
                      >

                        <VuiInput
                          type="text"
                          placeholder="Enter Option..."
                          name={`option-${optionIndex}`}
                          value={option}
                          onChange={(e) =>
                            handleOptionChange(e, indexOfFirstQuestion + questionIndex, optionIndex)
                          }
                          validation={!!quizerror.options}
                          sx={({ typography: { size } }) => ({
                            fontSize: size.sm,
                          })}
                        />
                         
                      </GradientBorder>
                    </Box>
                  ))}
                  {/* Input for Correct Answer */}
                  <VuiBox mb={1} mt={2}>
                    <VuiTypography
                      component="label"
                      variant="button"
                      color="white"
                      fontWeight="medium"
                    >
                      Correct Answer
                    </VuiTypography>
                    <GradientBorder
                      minWidth="100%"
                      padding="1px"
                      borderRadius={borders.borderRadius.lg}
                      backgroundImage={radialGradient(
                        palette.gradients.borderLight.main,
                        palette.gradients.borderLight.state,
                        palette.gradients.borderLight.angle
                      )}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <VuiInput
                        type="text"
                        placeholder="Enter Correct Answer"
                        name={`correctAnswer-${questionIndex}`}
                        value={q.correctAnswer} // Use the correctAnswer from the current question
                        onChange={(e) =>
                          handleCorrectAnswer(e, indexOfFirstQuestion + questionIndex)
                        } // Handle change for correct answer
                        validation={!!quizerror.correctAnswer}
                        sx={{ fontSize: "0.875rem" }} // Adjust the size as needed
                      />
                      {/* <DeleteIcon
                        onClick={() => handleDeleteQuestion(q._id)}
                        sx={{ color: "red", cursor: "pointer" }}
                      ></DeleteIcon> */}
                    </GradientBorder>
                    {quizerror.correctAnswer && (
                      <VuiTypography variant="caption" color="error" mt={1}>
                        {quizerror.correctAnswer}
                      </VuiTypography>
                    )}
                    <Grid></Grid>
                  </VuiBox>
                </VuiBox>
              </Grid>
            ))}

            {/* <Grid xs={2} sm={4} md={4}>
              <VuiBox mb={1} ml={2}>
                <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
                  Correct Answer
                </VuiTypography>
                <GradientBorder
                  minWidth="100%"
                  padding="1px"
                  borderRadius={borders.borderRadius.lg}
                  backgroundImage={radialGradient(
                    palette.gradients.borderLight.main,
                    palette.gradients.borderLight.state,
                    palette.gradients.borderLight.angle
                  )}
                >
                 

                  <VuiInput
                    type="text"
                    placeholder="Enter Correct Answer"
                    name="correctAnswer"
                    value={quizUpdateData.correctAnswer}
                    onChange={handleChange}
                    validation={!!quizerror.correctAnswer}
                    sx={({ typography: { size } }) => ({
                      fontSize: size.sm,
                    })}
                  />
                </GradientBorder>
                {quizerror.correctAnswer && (
                  <VuiTypography variant="caption" color="error" mt={1}>
                    {quizerror.correctAnswer}
                  </VuiTypography>
                )}
              </VuiBox>
            </Grid> */}
          </Grid>
          {/* <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
            justifyContent="center"
          >
            <Grid item xs={12} sm={8} md={4}>
              <VuiBox mt={5} mb={1}>
                <VuiButton color="info" fullWidth type="submit">
                  Update
                </VuiButton>
              </VuiBox>
            </Grid>
          </Grid> */}

          <Grid container justifyContent="center" mt={4}>
            <VuiButton
              color="info"
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              <KeyboardArrowLeftIcon></KeyboardArrowLeftIcon>
            </VuiButton>
            <VuiTypography color="white" variant="button" mx={2}>
              Page {currentPage} of {totalPages}
            </VuiTypography>
            <VuiButton
              color="info"
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              <KeyboardArrowRightIcon sx={{ fontSize: "20px !important" }}></KeyboardArrowRightIcon>
            </VuiButton>
          </Grid>
          {currentPage === totalPages && (
            <Grid container justifyContent="center">
              <Grid item xs={12} sm={8} md={4}>
                <VuiBox mt={5} mb={1}>
                  <VuiButton color="info" fullWidth type="submit">
                    Update
                  </VuiButton>
                </VuiBox>
              </Grid>
            </Grid>
          )}
        </VuiBox>
      </Box>

      <Footer />
    </DashboardLayout>
  );
}

export default UpdateQuiz;
