import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { LinkedinIcon, LinkedinShareButton } from 'react-share';
import { toPng } from 'html-to-image';
import axios from 'axios';
import { API_Host } from 'Api_utils';
import VuiButton from 'components/VuiButton';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function BasicModal({data}) {
  // console.log(data);
  const [open, setOpen] = React.useState(false);
  const [serverImageUrl, setServerImageUrl] = React.useState(""); 

  // const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleLinkedIn = () => {
  const imageUrl = serverImageUrl;

    const res = axios.get('http://localhost:5000/api/get-image',imageUrl).then((data)=>console.log(data).catch((err)=>console.log(err)));
    console.log(res);

  }

  const handleShareClick = async () => {
    await new Promise(resolve => setTimeout(resolve, 100)); // Small delay
    if (data.current) {
      try {
          const generatedImageUrl = await toPng(data.current, { skipFonts: true });
          const base64Response = await fetch(generatedImageUrl);
          const blob = await base64Response.blob();
          const formData = new FormData();
          formData.append("image", blob, "reward.png");
          const uploadResponse = await axios.post(`${API_Host}/save-image`, formData);
          const { imageUrl } = uploadResponse.data;
          setServerImageUrl(imageUrl);
          setOpen(true);
      } catch (error) {
          console.error("Failed to share image:", error);
      }
  }
  };
  console.log(serverImageUrl);
  const test = `http://portal1.appblocky.com/upload-share/${serverImageUrl}`;
  return (
    <div>
      <VuiButton onClick={handleShareClick} color="primary">Share</VuiButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
      <LinkedinShareButton url={`${API_Host}/get-image?imageUrl=${encodeURIComponent(test)}`} title={"Check out this reward!"}>
    <LinkedinIcon size={32} round />
    </LinkedinShareButton>

        </Box>
      </Modal>
    </div>
  );
}
