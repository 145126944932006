import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Grid, Card } from "@mui/material";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

// Custom Components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import VuiButton from "components/VuiButton";

// Data & Redux Actions
import { API_Host } from "Api_utils";
import { getTask, deleteTask } from "../../redux/slices/TaskSlice";
import "../../../src/app.css";

function ViewTask() {
  const dispatch = useDispatch();
  const taskData = useSelector((state) => state.task.task);
  const userRole = localStorage.getItem("role");
  const [fileNames, setFileNames] = useState({});
  const [uploadedFiles, setUploadedFiles] = useState({}); 
  // Fetch tasks from API


  const fetchTask = async () => {
    try {
      const res = await axios.get(`${API_Host}/get-task`);
      dispatch(getTask(res.data.data));
      console.log(res.data.data)
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchTask();
  }, []);

  

  // Delete task handler
  const handleDeleteTask = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to delete this Task? This action cannot be undone.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((taskResult) => {
      if (taskResult.isConfirmed) {
       
        Swal.fire("Deleted!", "Your Task has been deleted.", "success");

    

        axios.delete(`${API_Host}/delete-task/${id}`).then(() => {
          dispatch(deleteTask({ id }));
          fetchTask();
        });
      }
    
    });
  };

  // Handle file upload and update state for each task
  const handleFileChange = (event, taskId) => {
    const file = event.target.files[0];
    if (!file) return;

    // Update the fileNames state for the specific taskId
    setFileNames((prev) => ({
      ...prev,
      [taskId]: file.name, // Update only the corresponding task's file name
    }));
    console.log(taskId);

    setUploadedFiles((prev) => ({
      ...prev,
      [taskId]: file, // Store the actual file
    }));
  };
  console.log(fileNames)
  
    // Handle the submit action for a specific task
    const handleSubmit = async (taskId,taskName) => {
      if (!uploadedFiles[taskId]) {
        Swal.fire("Error", "Please upload a file before submitting.", "error");
        return;
      }
      const token = localStorage.getItem("token"); // Retrieve the token from local storage
      console.log(token);
  
      const formData = new FormData();
      formData.append("file", uploadedFiles[taskId]);
      formData.append("taskId", taskId); // If other fields 
      formData.append("taskName",taskName)
      // like taskName are required, append them too
      formData.append("status","incomplete");

  
      try {
        const response = await axios.post(`${API_Host}/submitted-task`, formData, {
          headers: 
          { 
          "Content-Type": "multipart/form-data" ,
          "Authorization": `Bearer ${token}`, // Include the token in the Authorization header
          }
        });
        console.log("File uploaded successfully", response);
        Swal.fire("Success", "File submitted successfully", "success");
      } catch (error) {
        console.error("Error uploading file", error);
        Swal.fire("Error", "Failed to upload file", "error");
      }
    };

  // Define table columns based on user role
  const studentColumn = [
    { name: "taskName", align: "center" },
    { name: "taskDetail", align: "center" },
    { name: "status", align: "center" },
    { name: "uploadbutton", align: "center" },
    { name: "submit", align: "center" },
  ];

  const instructorColumn = [
    { name: "taskName", align: "center" },
    // { name: "taskType", align: "center" },
    { name: "uploadFile", align: "center" },
    {name:"status",align:"center"},
    { name: "edit", align: "center" },
    { name: "delete", align: "center" },
  ];

  const column = userRole === "student" ? studentColumn : instructorColumn;

  const rows = taskData.map((task) => {
    // console.log(task.id)
    const studentRows = {
      taskName: task.taskName,
      status:task.status,
      taskDetail: (
        // <a href={`http://localhost:5000/uploads/${task.fileData}`} download="fileData">
        //   {task.fileData}
        // </a>
        <a href={`http://portal1.appblocky.com/${task.fileData}`} download='fileData'>
         {task.fileData}
        </a>
      ),
      uploadbutton: (
        <>
          <input
            type="file"
            id={`file-upload-${task.id}`}
            style={{ display: "none" }}
            onChange={(e) => handleFileChange(e, task.id)}
          />
          <VuiButton
            variant="contained"
            color="info"
            onClick={() => document.getElementById(`file-upload-${task.id}`).click()}
          >
            Upload
          </VuiButton>
          {/* Display uploaded file name only for this task */}
          {/* {fileNames[task._id] && (
            <div style={{ marginTop: "5px" }}>{fileNames[task._id]}</div>
          )} */}
          
          <p>{fileNames[task.id]}</p>
          {/* {
            (task.id === fileNames[task._id]) ? console.log("taskNAme") : console.log("error")
         
          } */}
          {/* {console.log(fileNames[task._id])} */}
          {/* <h1>{fileNames[task._id]}</h1> */}

        </>
      ),
     
      submit: (
        <VuiButton variant="contained" color="success" onClick={()=>handleSubmit(task.id,task.taskName)}>
          Submit
        </VuiButton>
      ),
    };

    const instructorRows = {
      taskName: task.taskName,
      taskType: task.taskType,
      status:task.status,
      uploadFile: (
        <img
          src={`http://portal1.appblocky.com/uploads/${task.fileData}`}
          alt="task file"
          style={{ width: "50px", height: "50px", objectFit: "cover" }}
        />
      ),
      edit: (
        <Link to={`/update-task/${task.id}`}>
          <EditIcon fontSize="small" className="editIcon" />
        </Link>
      ),
      delete: (
        <DeleteIcon
          onClick={() => handleDeleteTask(task.id)}
          fontSize="small"
          className="deleteIcon"
        />
      ),
    };

    return userRole === "student" ? studentRows : instructorRows;
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}  mt={5} sx={{height:'100vh'}}>
        <Card>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
              <VuiBox display="flex" justifyContent="space-between" alignItems="center">
                <VuiTypography variant="lg" color="white" style={{ marginLeft: "10px" }}>
                  Task
                </VuiTypography>
              </VuiBox>
            </Grid>
            <Grid item xs={6}>
              {userRole !== "student" && (
                <div className="bttn">
                  <Link to={`/add-task`} className="link">
                    <VuiButton sx={{ marginBottom: "15px" }} variant="contained" color="info">
                      <AddIcon style={{ marginRight: "10px" }} />
                      Add Task
                    </VuiButton>
                  </Link>
                </div>
              )}
            </Grid>
          </Grid>

          <VuiBox
            sx={{
              "& th": {
                borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                  `${borderWidth[1]} solid ${grey[700]}`,
              },
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                    `${borderWidth[1]} solid ${grey[700]}`,
                },
              },
            }}
          >
            <Table columns={column} rows={rows} />
          </VuiBox>
        </Card>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ViewTask;
