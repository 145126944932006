import { createSlice } from "@reduxjs/toolkit";

const assignTaskSlice = createSlice({
  name: "assignTask",
  initialState: {
    assignTask: [],
    allAssignTask: [],
    assignCount: 0,
    completedTaskCount: 0,
    incompleteTaskCount:0
  },
  reducers: {
    // addTask:(state,action) => {
    //     state.task.push(action.payload);
    // },
    //this one is for student
    getAssignTask: (state, action) => {
      state.assignTask = action.payload.map((assignTaskData) => {
        return {
          id: assignTaskData._id,
          taskName: assignTaskData.taskName,
          // taskType: assignTaskData.taskType,
          rewardPoints:assignTaskData.rewardPoints,
          // taskFile:taskSubmitData.taskFile,
          status: assignTaskData.status,
          // studentFile:assignTaskData.studentFile.fileName,
          taskFile: assignTaskData.taskFile,
          submission:assignTaskData.submission

          // link:assignTaskData.link

          // from:taskSubmitData.from
        };
      });
    },
    updateAssignTask: (state, action) => {
      const index = state.assignTask.findIndex(
        (assignTaskData) => assignTaskData.id === action.payload
      );
      state.assignTask[index] = {
        id: action.payload.id,
        status: action.payload.status,
      };
    },
    //this one is for instructor
    getAllAssignTask: (state, action) => {
      state.allAssignTask = action.payload.map((assignTaskData) => {
        console.log(assignTaskData);
        const studentFile = assignTaskData.studentFile;
        return {
          id: assignTaskData._id,
          taskName: assignTaskData.taskName,
          rewardPoints:assignTaskData.rewardPoints,
          // taskType: assignTaskData.taskType,
          taskFile:assignTaskData.taskFile,
          status: assignTaskData.status,
          assigned: assignTaskData.assigned,
          // studentFile: assignTaskData.studentFile.fileName,
          // linkFile:assignTaskData.studentFile.link
          // from:taskSubmitData.from
          studentFile: {
            fileName: studentFile.fileName || "", // Extract fileName
            link: studentFile.link || "", // Extract link
          },
          submission:assignTaskData.submission
        };
      });
    },
    deleteAssignTask: (state, action) => {
      const id = action.payload;
      state.assignTask = state.assignTask.filter((assignTaskData) => assignTaskData.id !== id);
    },
    getAssignCount: (state, action) => {
      state.assignCount = action.payload;
    },
    getCompletedTaskStatus: (state, action) => {
      state.completedTaskCount = action.payload;
    },
    getIncompleteTaskStatus:(state,action) => {
        state.incompleteTaskCount = action.payload; 
    }
  },
});

export const {
  getAssignTask,
  updateAssignTask,
  getAllAssignTask,
  deleteAssignTask,
  getAssignCount,
  getCompletedTaskStatus,
  getIncompleteTaskStatus
} = assignTaskSlice.actions;
export default assignTaskSlice.reducer;
