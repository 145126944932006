// Vision UI Dashboard React layouts and icons
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import Quiz from "layouts/billing";
import RTL from "layouts/rtl";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import Review from "layouts/review/Review";
import AddCourses from "layouts/addcourses/AddCourses";
import ViewCourses from "layouts/addcourses/ViewCourses";
import EnrollStudent from "layouts/EnrollStudent/EnrollStudent";
import ViewEnrollStudent from "layouts/EnrollStudent/ViewEnrollStudent";
import Instructor from "layouts/Instructor/Instructor";
import ViewInstructor from "layouts/Instructor/View-Instructor";
import ViewRewards from "layouts/Rewards/ViewRewards";
import ViewTask from "layouts/Task/ViewTask";
import ViewQuiz from "layouts/quiz/ViewQuiz";
import { IoRocketSharp, IoBuild, IoHome, IoStatsChart, IoDocument } from "react-icons/io5";
import { BsFillPersonFill, BsCreditCardFill } from "react-icons/bs";
import PersonIcon from '@mui/icons-material/Person';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import TaskIcon from '@mui/icons-material/Task';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import ViewSubmitTask from "layouts/submitTask/ViewSubmitTask";
// import SubmittedTask from "layouts/Task/SubmittedTask";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AssignTask from "layouts/Task/Instructor/AssignTask";
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import ViewAssignedTask from "layouts/Task/viewAssignedTask";

export const getAdminRoutes = () => {
  return [
    {
      type: "collapse",
      name: "Dashboard",
      key: "dashboard",
      route: "/dashboard",
      icon: <IoHome size="15px" color="inherit" />,
      component: Dashboard,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Courses",
      key: "courses",
      route: "/courses",
      icon: <AutoStoriesIcon size="15px" color="inherit" />,
      component: ViewCourses,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Enroll Student",
      key: "enroll-student",
      route: "/view-student",
      icon: <PersonIcon size="15px" color="inherit" />,
      component: ViewEnrollStudent,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Instructor",
      key: "instructor",
      route: "/view-instructor",
      icon: <PersonIcon size="15px" color="inherit" />,
      component: ViewInstructor,
      noCollapse: true,
    },
    // {
    //   type: "collapse",
    //   name: "Task",
    //   key: "task",
    //   route: "/task",
    //   icon: <TaskIcon size="15px" color="inherit" />,
    //   component: ViewTask,
    //   noCollapse: true,
    // },
    // {
    //   type: "collapse",
    //   name: "Quiz",
    //   key: "quiz",
    //   route: "/quiz",
    //   icon: <IoStatsChart size="15px" color="inherit" />,
    //   component: ViewQuiz,
    //   noCollapse: true,
    // },
    {
      type: "collapse",
      name: "Rewards",
      key: "rewards",
      route: "/view-rewards",
      icon: <EmojiEventsIcon size="15px" color="inherit" />,
      component: ViewRewards,
      noCollapse: true,
    },
    // {
    //   type: "collapse",
    //   name: "Review",
    //   key: "review",
    //   route: "/review",
    //   icon: <BsCreditCardFill size="15px" color="inherit" />,
    //   component: Review,
    //   noCollapse: true,
    // },
    // {
    //   type: "collapse",
    //   name: "RTL",
    //   key: "rtl",
    //   route: "/rtl",
    //   icon: <IoBuild size="15px" color="inherit" />,
    //   component: RTL,
    //   noCollapse: true,
    // },
    { type: "title", title: "Account Pages", key: "account-pages" },
    // {
    //   type: "collapse",
    //   name: "Profile",
    //   key: "profile",
    //   route: "/profile",
    //   icon: <BsFillPersonFill size="15px" color="inherit" />,
    //   component: Profile,
    //   noCollapse: true,
    // },
    {
      type: "collapse",
      name: "Sign In",
      key: "sign-in",
      route: "/authentication/sign-in",
      icon: <IoDocument size="15px" color="inherit" />,
      component: SignIn,
      noCollapse: true,
    },
    // {
    //   type: "collapse",
    //   name: "Sign Up",
    //   key: "sign-up",
    //   route: "/authentication/sign-up",
    //   icon: <IoRocketSharp size="15px" color="inherit" />,
    //   component: SignUp,
    //   noCollapse: true,
    // },
  ];
};

export const getStudentRoutes = () => {
  return [
    {
      type: "collapse",
      name: "Dashboard",
      key: "dashboard",
      route: "/dashboard",
      icon: <IoHome size="15px" color="inherit" />,
      component: Dashboard,
      noCollapse: true,
    },
     
    // {
    //   type: "collapse",
    //   name: "LeaderBoard",
    //   key: "leaderboard",
    //   route: "/dashboard",
    //   icon: <LeaderboardIcon size="15px" color="inherit" />,
    //   component: Dashboard,
    //   noCollapse: true,
    // },
    {
      type: "collapse",
      name: "Task",
      key: "task",
      route: "/view-assigned-task",
      icon: <TaskIcon size="15px" color="inherit" />,
      component: ViewAssignedTask,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Quiz",
      key: "quiz",
      route: "/quiz",
      icon: <IoStatsChart size="15px" color="inherit" />,
      component: ViewQuiz,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Profile",
      key: "profile",
      route: "/profile",
      icon: <BsFillPersonFill size="15px" color="inherit" />,
      component: Profile,
      noCollapse: true,
    },
    // {
    //   type: "collapse",
    //   name: "Rewards",
    //   key: "rewards",
    //   route: "/view-rewards",
    //   icon: <EmojiEventsIcon size="15px" color="inherit" />,
    //   component: ViewRewards,
    //   noCollapse: true,
    // },
   
  ];
};

export const getInstructorRoutes = () => {
  return [
    {
      type: "collapse",
      name: "Dashboard",
      key: "dashboard",
      route: "/dashboard",
      icon: <IoHome size="15px" color="inherit" />,
      component: Dashboard,
      noCollapse: true,
    },
    // {
    //   type: "collapse",
    //   name: "Courses",
    //   key: "courses",
    //   route: "/courses",
    //   icon: <PersonIcon size="15px" color="inherit" />,
    //   component: ViewCourses,
    //   noCollapse: true,
    // },
    {
      type: "collapse",
      name: "Task",
      key: "task",
      route: "/task",
      icon: <TaskIcon size="15px" color="inherit" />,
      component: ViewTask,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Assign Task",
      key: "assign task",
      route: "/view-assigned-task",
      icon: <AssignmentIndIcon size="15px" color="inherit" />,
      component: AssignTask,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Quiz",
      key: "quiz",
      route: "/quiz",
      icon: <IoStatsChart size="15px" color="inherit" />,
      component: ViewQuiz,
      noCollapse: true,
    },
    // {
    //   type: "collapse",
    //   name: "Rewards",
    //   key: "rewards",
    //   route: "/view-rewards",
    //   icon: <EmojiEventsIcon size="15px" color="inherit" />,
    //   component: ViewRewards,
    //   noCollapse: true,
    // },
    // {
    //   type: "collapse",
    //   name: "Submitted Tasks",
    //   key: "submittedTask",
    //   route: "/submitted-tasks",
    //   icon: <EmojiEventsIcon size="15px" color="inherit" />,
    //   component: SubmittedTask,
    //   noCollapse: true,
    // },
   
  ];
};


