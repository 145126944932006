// OrdersOverview.js

// @mui material components
import Card from "@mui/material/Card";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// React icons
import { FaBell, FaShoppingCart } from "react-icons/fa";
import { IoLogoCss3 } from "react-icons/io";
import { BsCreditCardFill } from "react-icons/bs";
import { SiDropbox } from "react-icons/si";

// Vision UI Dashboard React example components
import TimelineItem from "examples/Timeline/TimelineItem";
import AdobeXD from "examples/Icons/AdobeXD";

// Vision UI Dashboard theme imports
import palette from "assets/theme/base/colors";
import { API_Host } from "Api_utils";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { toPng } from "html-to-image";
import { ShareSocial } from "react-share-social";
import { LinkedinIcon, LinkedinShareButton, TwitterIcon, TwitterShareButton } from "react-share";
import BasicModal from "components/ShareComponent";

function OrdersOverview() {
  const [users, setUsers] = useState([]);
  const cardRef = useRef();
  const [serverImageUrl, setServerImageUrl] = useState(""); 

  useEffect(() => {
    const fetchUsers = async () => {
      try { 
        const response = await axios.get(`${API_Host}/get-user-role/student`);
        setUsers(response.data.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    fetchUsers();
  }, []);

  const handleShareClick = async () => {
    await new Promise(resolve => setTimeout(resolve, 100)); // Small delay
    if (cardRef.current) {
      try {
        const generatedImageUrl = await toPng(cardRef.current, { skipFonts: true });
  
        // Convert base64 data URL to Blob
        const base64Response = await fetch(generatedImageUrl);
        const blob = await base64Response.blob();
  
        const formData = new FormData();
        formData.append("image", blob, "reward.png");
  
        const uploadResponse = await axios.post("http://localhost:5000/api/save-image", formData);
        const result = await uploadResponse.data; // axios auto-parses JSON
        // const test = "http://portal1.appblocky.com/uploads/1728712121287-flower2.jpg"
        console.log(result);
        setServerImageUrl(result);
        console.log(test);
        // const test = "http://portal1.appblocky.com/uploads/1728712121287-flower2.jpg";
  
        // const linkedInShareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(test)}&title=${encodeURIComponent("testing my app bro")} &summary=${encodeURIComponent("testing my app!")}&source=YourSource`;
        // window.open(linkedInShareUrl, '_blank');
        console.log(result);
      } catch (error) {
        console.error("Failed to share image:", error);
      }
    }
  };
  console.log(serverImageUrl);
  
  // const getRankedUsers = () => {
  //   let rank = 1;
  //   let previousPoints = null;

  //   return users.map((user, index) => {
  //     if (previousPoints === user.rewardPoints) {
  //       return { ...user, rank };
  //     } else {
  //       rank = index + 1;
  //       previousPoints = user.rewardPoints;
  //       return { ...user, rank };
  //     }
  //   });
  // };
  
  // const rankedUsers = getRankedUsers();


  const getRankedUsers = () => {
    // Sort users by rewardPoints in descending order
    const sortedUsers = [...users].sort((a, b) => b.rewardPoints - a.rewardPoints);

    // Assign ranks based on sorted reward points
    let rank = 1;
    let previousPoints = null;

    return sortedUsers.map((user, index) => {
      if (previousPoints === user.rewardPoints) {
        return { ...user, rank };
      } else {
        rank = index + 1;
        previousPoints = user.rewardPoints;
        return { ...user, rank };
      }
    });
  };

  const rankedUsers = getRankedUsers();
  return (
    <Card className="h-100" ref={cardRef}>
     
      
      <VuiBox mb="16px">
        <VuiTypography variant="lg" fontWeight="bold" mb="5px" color="white">
          Leader Board In All Programs
        </VuiTypography>
        
        {/* <button onClick={handleShareClick}>Share on LinkedIn</button> */}
        {/* <ShareSocial  
  url ="url_to_share.com"
  socialTypes= {['facebook','twitter']}
  onSocialButtonClicked={ data => console.log(data)}    
/> */}
<BasicModal data={cardRef}/>

      </VuiBox>

      <VuiBox>
        {users.length > 0 ? (
          rankedUsers.map((user) => (
            <TimelineItem
              key={user._id}
              icon={<FaBell size="16px" color={palette.info.main} />}
              title="Design changes"
              rank={user.rank}
              name={user.name}
              rewardPoints={user.rewardPoints}
              dateTime="22 DEC 7:20 PM"
            />
          ))
        ) : (
          <p>Loading users...</p>
        )}
       
      </VuiBox>
    </Card>
  );
}

export default OrdersOverview;
