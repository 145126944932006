
import * as React from 'react';
import Card from "@mui/material/Card";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import GradientBorder from "examples/GradientBorder";

// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import borders from "assets/theme/base/borders";
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import Grid from '@mui/material/Grid';
import { Box } from "@mui/material";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useState } from "react";
import 'app.css'
import { Password } from '@mui/icons-material';
import { useDispatch } from 'react-redux';

import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { API_Host } from 'Api_utils';
import { addReward } from '../../redux/slices/RewardSlice';
import Swal from "sweetalert2";

function AddRewards() {

  
 
  const dispatch = useDispatch();
  const history = useHistory ();
  const [rewards,setRewards] = React.useState('')

  // enroll fields state
  const [rewardsData, setRewardsData] = useState({
    rewardType: "",
    points: "",
    
    // rewardPoints: "",
    // freelancer: ""
  });

  // validation 
  const [rewarderror,setRewardError] = useState({})

  const handleReward = (e) => {
    e.preventDefault();

    // validation
    const handleRewardValidate = rewardValidate()
    if(!handleRewardValidate){
      return
    }

    axios.post(`${API_Host}/add-rewards`,rewardsData)
    .then(res => {
      dispatch(addReward(res.data))
      console.log("Reward added Successfully ",res.data);
      Swal.fire({
        title: "Rewards Added Successfully!",
        text: "Your Rewards has been added.",
        icon: "success",
        showClass: {
          popup: `
            animate__animated
            animate__fadeInUp
            animate__faster
          `
        },
        hideClass: {
          popup: `
            animate__animated
            animate__fadeOutDown
            animate__faster
          `
        }
      });
      history.push('/view-rewards')
    })
    .catch(err =>console.log(err) )
   
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setRewardsData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    const error = {...rewarderror};
if(value.trim() === ""){
error[name] =``;

}
else{
  delete error[name]
}
setRewardError(error)
  };

  const handleRewardType = (event) => {
    const selectedReward = event.target.value
    setRewards(selectedReward);
    setRewardsData((prevData) => ({
      ...prevData,
      rewardType: selectedReward,
    }));

    const error = { ...rewarderror };
    if (selectedReward) {
      delete error.rewardType; 
    }
    setRewardError(error);
  }
// validation
  const rewardValidate  = async() =>{
    const error = {}
    console.log(error)
    if(!rewardsData.rewardType) error.rewardType = 'RewardType is required'
    if(!rewardsData.points) error.points = 'Points is required'
   
    
    setRewardError(error)
    return Object.keys(error).length === 0;
     }

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Box sx={{ width: '100%' }} mt={15} mb={38}>
        <VuiBox component="form" role="form" onSubmit={handleReward}>
        {/* first col */}
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid  xs={2} sm={4} md={6}>
        <VuiBox mb={1} ml={2}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
           
              RewardType
            </VuiTypography>
            <GradientBorder
            minWidth="100%"
            padding="1px"
            borderRadius={borders.borderRadius.lg}
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
            {/* <VuiInput type="text" placeholder="Enter Your Courses..." fontWeight="500"
            name='rewardType'
            value={rewardsData.rewardType}
            onChange={handleChange}
             /> */}

<Select 
              sx={{
                backgroundColor:"#0f1535 !important",
                color:"white !important",
                borderRadius:"0.975rem !important"
              }}

         minWidth="100%"
          value={rewards}
          onChange={handleRewardType}
          error={!!rewarderror.rewardType}
        >
          <MenuItem value={"normal"}>Normal</MenuItem>
          <MenuItem value={"quiz"}>Quiz</MenuItem>
          <MenuItem value={"special"}>Special</MenuItem>
          
          
        </Select>
          </GradientBorder>
          {rewarderror.rewardType && (
            <VuiTypography variant="caption" color="error" mt={1}>
              {rewarderror.rewardType}
            </VuiTypography>
          )}
        </VuiBox>
        </Grid>
{/* end */}

{/* second col */}
        <Grid xs={2} sm={4} md={6}>
        <VuiBox mb={1} ml={2}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
           Points
            </VuiTypography>
            <GradientBorder
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
             <VuiInput
              type="number"
              placeholder="Enter Points..."
              name='points'
              value={rewardsData.points}
              onChange={handleChange}
              error={!!rewarderror.points}
              sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })}
            />
          </GradientBorder>
          {rewarderror.points && (
            <VuiTypography variant="caption" color="error" mt={1}>
              {rewarderror.points}
            </VuiTypography>
          )}
          </VuiBox>
        </Grid>
       {/* end */}

      
        
      </Grid>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} justifyContent="center">
  <Grid item xs={12} sm={8} md={4}>
    <VuiBox mt={5} mb={1}>
      <VuiButton color="info" fullWidth type="submit">
        Submit
      </VuiButton>
    </VuiBox>
  </Grid>
</Grid>
</VuiBox>
    </Box>



     
      <Footer />
    </DashboardLayout>
  );
}

export default AddRewards;
