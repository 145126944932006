
import Card from "@mui/material/Card";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import Swal from "sweetalert2";
// Data

import { Link } from "react-router-dom";
import { Button, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import axios from "axios";
import { API_Host } from "Api_utils";
import { getQuiz, deleteQuiz } from "../../redux/slices/QuizSlice";
import VuiButton from "components/VuiButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import "../../../src/app.css";
import { ShareSocial } from "react-share-social";
import { useState } from "react";
import VuiInput from "components/VuiInput";

function ViewQuiz() {
  const dispatch = useDispatch();
  const quizData = useSelector((state) => state.quiz.quiz);
  const userRole = localStorage.getItem("role");
  const storedCourse = localStorage.getItem("course");

  console.log(quizData);

  const [searchStudentQuiz, setStudentQuiz] = useState("");

  // const filteredStudentQuiz = quizData.filter(
  //   (quizData) =>
  //    (quizData.topic &&   quizData.topic .toLowerCase().includes(searchStudentQuiz.toLowerCase())) 

  // );
  const filteredStudentQuiz = quizData.filter((quiz) => {
    const matchesSearch = quiz.topic && quiz.topic.toLowerCase().includes(searchStudentQuiz.toLowerCase());
    if (userRole === "student") {
      const matchesCourse = storedCourse && quiz.assignedBy === storedCourse;
      return matchesCourse && matchesSearch; // Student sees only quizzes for their course
    }
    return matchesSearch; // Instructor sees all quizzes
  });

  console.log("Filtered Quizzes:", filteredStudentQuiz);
  // get call

  const fetchQuiz = async () => {
    try {
      const res = await axios.get(`${API_Host}/get-quiz`);
      dispatch(getQuiz(res.data.data));
      console.log("fetch quiz data", res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchQuiz();
  }, []);

  
    

  //   const fetchQuizz = async (id) => {
  //     try {
  //         const token = localStorage.getItem("token"); // Retrieve the token from local storage
  //         const res = await axios.get(`${API_Host}/get-particular-quiz/${id}`, {
  //             headers: { Authorization: `Bearer ${token}` }
  //         });
  //         dispatch(getQuiz(res.data.data));
  //         console.log("fetch quiz data", res.data.data);
  //     } catch (err) {
  //         console.log(err);
  //     }
  //     useEffect(() => {
  //       fetchQuizz();
  //     })
  // };

  // delete api
  const handleDeleteQuiz = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to delete this Quiz? This action cannot be undone.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    })
      .then((quizResult) => {
        if (quizResult.isConfirmed) {
          Swal.fire({
            title: "Deleted!",
            text: "Your Quiz has been deleted.",
            icon: "success",
          });

          axios.delete(`${API_Host}/delete-quiz/` + id).then((res) => {
            dispatch(deleteQuiz({ id }));
            console.log("quiz deleted successfully", res);
            fetchQuiz();
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

 

  const studentQuizColumn = [
    { name: "topicName", align: "center" },
    { name: "quizQuestion", align: "center" },
    // {name:"file",align:"center"},
    // {name:"action",align:"center"},
    // { name: "uploadFile", align: "center" },
  ];

  const instructorQuizColumn = [
    { name: "topicName", align: "center" },
    // { name: "quizType", align: "center" },
    // { name: "question", align: "center" },
    // {name:"options",align:"center"},
    // {name:"correctAnswer",align:"center"},
    { name: "edit", align: "center" },
    { name: "delete", align: "center" },
  ];

  const column = userRole === "student" ? studentQuizColumn : instructorQuizColumn;

  const rows = filteredStudentQuiz.map((quizData) => {
    const hasAttended = quizData.attendedBy.some(
      (user) => user.email === localStorage.getItem("email")
    );

    const studentQuizRows = {
      topicName: quizData.topic,
      quizQuestion: !hasAttended ? (
        <Link to={`/particular-quiz/${quizData.id}`}>
          <span style={{ color: "white" }}>View Question</span>
        </Link>
      ) : (
        <Link to={`/quiz-result/${quizData.id}`}>
          <span style={{ color: "white" }}>View Result</span>
        </Link>
      ),
      edit: (
        <>
          <Link to={`/update-Quiz/${quizData.id}`}>
            {/* <Button>Edit</Button> */}
            <EditIcon fontSize="small" className="editIcon"></EditIcon>
          </Link>
        </>
      ),
      delete: (
        <>
          {/* <Button onClick={() => handleDeleteQuiz(quizData.id)}>Delete</Button> */}
          <DeleteIcon
            onClick={() => handleDeleteQuiz(quizData.id)}
            className="deleteIcon"
            fontSize="small"
          ></DeleteIcon>
        </>
      ),
    };
    console.log(quizData);
    const instructorQuizRows = {
      topicName: quizData.topic,
      // studentEmail: quizData.assignedBy,
      // question:quizData.question.map(question => question ),
      // quizType:quizData.quizType,
      // question: quizData.question,
      // options: quizData.options,
      // options:quizData.options,
      //     options: quizData.options
      // .map(option => option),

      // question: quizData.questions.map((q, index) => (
      //   <div key={index}>
      //      {q.question}

      //       {q.options.map((option, i) => (
      //         <li key={i}>{option}</li>
      //       ))}

      //     {q.correctAnswer}
      //   </div>
      // )),
      // correctAnswer:quizData.correctAnswer,
      quizQuestion: (
        <Link to={`/particular-quiz/${quizData.id}`}>
          <a href="/particular-quiz/:id" style={{ color: "white" }}>
            ViewQuestion{" "}
          </a>
        </Link>
      ),
      

      // uploadFile: (
      //   <>
      //     {quizData.fileData && quizData.fileData.endsWith(".pdf") ? (
      //       <iframe
      //         src={`http://localhost:5000/uploads-quiz/${quizData.fileData}`}
      //         width="50px"
      //         height="50px"
      //         title="quizfile"
      //       />
      //     ) : (
      //       <img
      //         src={`http://localhost:5000/uploads-quiz/${quizData.fileData}`}
      //         alt="quizfile"
      //         style={{ width: "50px", height: "50px", objectFit: "cover" }}
      //       />
      //     )}
      //   </>
      // ),

      edit: (
        <>
          <Link to={`/update-Quiz/${quizData.id}`}>
            {/* <Button>Edit</Button> */}
            <EditIcon fontSize="small" className="editIcon"></EditIcon>
          </Link>
        </>
      ),
      delete: (
        <>
          {/* <Button onClick={() => handleDeleteQuiz(quizData.id)}>Delete</Button> */}
          <DeleteIcon
            onClick={() => handleDeleteQuiz(quizData.id)}
            className="deleteIcon"
            fontSize="small"
          ></DeleteIcon>
        </>
      ),
    };
    return userRole === "student" ? studentQuizRows : instructorQuizRows;
  });

  console.log(rows);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}  mt={3} sx={{height:'100vh'}}>
        <VuiBox mb={8}></VuiBox>
        {/* <Link  to={`/add-quiz`} >
      <Button sx={{marginLeft:"10px"}}>AddQuiz</Button>
      <VuiButton sx={{marginBottom:"15px",marginLeft:"15px"}} variant="contained" color="info">Add Quiz</VuiButton>
      </Link> */}

        <Card>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
              <VuiBox display="flex" justifyContent="space-between" alignItems="center">
                <VuiTypography variant="lg" color="white" style={{ marginLeft: "10px" }}>
                  Quiz
                </VuiTypography>
              </VuiBox>
            </Grid>
            <Grid container item xs={6} justifyContent="flex-end" alignItems="center" marginBottom="20px">
              {userRole !== "student" && (
                <div className="bttn">
                  <Link to={`/add-quiz`} className="link">
                    <VuiButton sx={{ marginBottom: "15px" }} variant="contained" color="info">
                      <AddIcon style={{ marginRight: "10px" }}></AddIcon>
                      Add Quiz
                    </VuiButton>
                  </Link>
                </div>
              )}

              <Grid item>
                <VuiBox style={{ width: "100%", maxWidth: "250px", paddingBottom: "15px" }}>
                  <VuiInput
                    placeholder="Type here..."
                    icon={{ component: "search", direction: "left" }}
                    sx={({ breakpoints }) => ({
                      [breakpoints.down("sm")]: {
                        maxWidth: "80px",
                      },
                      [breakpoints.only("sm")]: {
                        maxWidth: "80px",
                      },
                      backgroundColor: "info.main !important",
                    })}
                    value={searchStudentQuiz}
                    onChange={(e) => setStudentQuiz(e.target.value)}
                  />
                </VuiBox>
              </Grid>
            </Grid>
          </Grid>

          <VuiBox
            sx={{
              "& th": {
                borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                  `${borderWidth[1]} solid ${grey[700]}`,
              },
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                    `${borderWidth[1]} solid ${grey[700]}`,
                },
              },
            }}
          >
            <Table columns={column} rows={rows} />
          </VuiBox>
        </Card>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ViewQuiz;
