
// @mui material components
import * as React from 'react';
import Card from "@mui/material/Card";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import GradientBorder from "examples/GradientBorder";

// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import borders from "assets/theme/base/borders";
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import Grid from '@mui/material/Grid';
import { Box } from "@mui/material";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useState } from "react";
import 'app.css'
import { Password } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { addEnrollStudent, updateEnrollStudent } from '../../../src/redux/slices/EnrollSlice';
import axios from 'axios';
import { API_Host } from 'Api_utils';
import { useHistory, useParams } from 'react-router-dom';
import Swal from "sweetalert2";

function UpdateStudent() {

  const [language, setLanguage] = React.useState('');
  const [course,setCourses] = React.useState('');
  const [freelancer,setFreenLancer] = React.useState('');
  const [role,setRole] = React.useState('')

  const dispatch = useDispatch();
  const student = useSelector(state=> state.enrollStudent.enrollStudent);
  const {id} = useParams();
  const user = student.find(student => student.id === id);
  console.log(user);
  const history = useHistory();

  const [studenterrors, setStudentErrors] = useState({});

  // enroll fields state
  const [enrollUpdateData, setEnrollUpdateData] = useState({
    name: user ? user.name : "",
    email: user ? user.email : "",
    // password: user ? user.password : "",
    role: user ? user.role : "",
    phone: user ? user.phone : "",
    language: user ? user.language : "",
    course: user ? user.course : "",
    // rewardPoints: user ? user.rewardPoints : "",
    // freelancer: user ? user.freelancer : ""
  
  });

  const handleUpdateEnroll = async (e) => {
    e.preventDefault();

    const instructorValidation = await validate()
    if(!instructorValidation){
      // console.log("Validation failed, preventing submission.");
      return
    }
    // console.log(`${API_Host}/edit-user/${id}`);
    axios.put(`${API_Host}/edit-user/${id}`, enrollUpdateData)
    .then(res => {
      dispatch(updateEnrollStudent(res.data))
      console.log("Enroll Student Successfully Updated",res.data);
      Swal.fire({
        title: "Student Updated Successfully!",
        text: "Student has been updated.",
        icon: "success",
        showClass: {
          popup: `
            animate__animated
            animate__fadeInUp
            animate__faster
          `
        },
        hideClass: {
          popup: `
            animate__animated
            animate__fadeOutDown
            animate__faster
          `
        }
      });
      history.push('/view-student')
    })
    .catch(err =>console.log(err) )
   
  }

   //   to fetch Coursename state
const [courseName,setCourseName] = useState([]);

React.useEffect(() => {
    const fetchCourseName = async () => {
      try {
        const response = await axios.get(`${API_Host}/get-course`);
        setCourseName(response.data.data.map(course => course.courseName));
      } catch (error) {
        console.error("Error fetching coursename:", error);
      }
    };

    fetchCourseName();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setEnrollUpdateData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    const newErrors = { ...studenterrors };
    if (value.trim() === '') {
      // newErrors[name] = ``; 
    } else {
      delete newErrors[name]; 
    }
    
    // Update the error state
    setStudentErrors(newErrors);
  };


  const handleLanguage = (event) => {
    const selectedLanguage = event.target.value
    setLanguage(selectedLanguage);
    setEnrollUpdateData((prevData) => ({
      ...prevData,
      language: selectedLanguage,
    }));
    
  };

  const handleCourses = (event) => {
    const selectedCourses = event.target.value
    setCourses(selectedCourses);
    setEnrollUpdateData((prevData) => ({
      ...prevData,
      course: selectedCourses,
    }));
  }

  // const handleFreeLancer = (event) => {
  //   const selectedFreeLancer = event.target.value
  //   setFreenLancer(selectedFreeLancer);
  //   setEnrollUpdateData((prevData) => ({
  //     ...prevData,
  //     freelancer: selectedFreeLancer,
  //   }));
  // }

  const handleRole = (event) => {
    const selectedRole = event.target.value
    setRole(selectedRole);
    setEnrollUpdateData((prevData) => ({
      ...prevData,
      role: selectedRole,
    }));
  }
  
   // validation
const validate = async() => {
  const newErrors = {};
  console.log(newErrors);
  if (!enrollUpdateData.name) newErrors.name = "InstructorName is required";
  if (!enrollUpdateData.email) newErrors.email = "Email is required";
  else if (!/\S+@\S+\.\S+/.test(enrollUpdateData.email)) newErrors.email = "Email is invalid";
  
  // if (!enrollUpdateData.password) newErrors.password = "Password is required";
  if (!enrollUpdateData.phone) newErrors.phone = "Phone number is required";
  else if (!/^\d{10}$/.test(enrollUpdateData.phone)) newErrors.phone = "Phone number must contain only 10 digits";
  if (!enrollUpdateData.phone) newErrors.role = "Role is required";
  if (!enrollUpdateData.language) newErrors.language = "Language is required";
  if (!enrollUpdateData.course) newErrors.course = "Course is required";

  // console.log("validate error",newErrors);
  setStudentErrors(newErrors);
  return Object.keys(newErrors).length === 0;
};

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Box sx={{ width: '100%',height:'70vh' }} mt={18} >
        <VuiBox component="form" role="form" onSubmit={handleUpdateEnroll}>
        {/* first col */}
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid  xs={2} sm={4} md={4}>
        <VuiBox mb={1} ml={2}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
           
              Name
            </VuiTypography>
            <GradientBorder
            minWidth="100%"
            padding="1px"
            borderRadius={borders.borderRadius.lg}
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
            <VuiInput type="text" placeholder="Enter Your Courses..." fontWeight="500"
            name='name'
            value={enrollUpdateData.name}
            onChange={handleChange}
            error={!!studenterrors.name}
             />
          </GradientBorder>
          {studenterrors.name && (
    <VuiTypography variant="caption" color="error" mt={1}>
      {studenterrors.name}
    </VuiTypography>
  )}
        </VuiBox>
        </Grid>
{/* end */}

{/* second col */}
        <Grid xs={2} sm={4} md={4}>
        <VuiBox mb={1} ml={2}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
           Email
            </VuiTypography>
            <GradientBorder
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
             <VuiInput
              type="email"
              placeholder="Enter Email..."
              name='email'
              value={enrollUpdateData.email}
              onChange={handleChange}
              error={!!studenterrors.email}
              sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })}
            />
          </GradientBorder>
          {studenterrors.email && (
    <VuiTypography variant="caption" color="error" mt={1}>
      {studenterrors.email}
    </VuiTypography>
  )}
          </VuiBox>
        </Grid>
       {/* end */}

       {/* third col */}

       {/* <Grid xs={2} sm={4} md={4}>
        <VuiBox mb={1} ml={2}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              Password
            </VuiTypography>
            <GradientBorder
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
             <VuiInput
              type="Password"
              placeholder="Enter password..."
              name='password'
              value={enrollUpdateData.password}
              onChange={handleChange}

              sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })}
            />
          </GradientBorder>
          </VuiBox>
        </Grid> */}
       {/* end */}

       {/* fourth col */}
       <Grid xs={2} sm={4} md={4}>
        <VuiBox mb={1} ml={2}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              Role
            </VuiTypography>
            <GradientBorder
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
             {/* <VuiInput
              type="text"
              placeholder="Filter type..."
              sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })}
            /> */}
          <Select 
              sx={{
                backgroundColor:"#0f1535 !important",
                color:"white !important",
                borderRadius:"0.975rem !important"
              }}

         minWidth="100%"
          value={enrollUpdateData.role}
          onChange={handleRole}
          error={!!studenterrors.role}
        >
          <MenuItem value={"admin"}>Admin</MenuItem>
          <MenuItem value={"instructor"}>Instructor</MenuItem>
          <MenuItem value={"student"}>Student</MenuItem>
          
          
        </Select>

          </GradientBorder>
          {studenterrors.role && (
    <VuiTypography variant="caption" color="error" mt={1}>
      {studenterrors.role}
    </VuiTypography>
  )}
          </VuiBox>
        </Grid>
       {/* end */}

       {/* fifth col */}
       <Grid xs={2} sm={4} md={4}>
        <VuiBox mb={1} ml={2}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              Phone
            </VuiTypography>
            <GradientBorder
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
             <VuiInput
              type="number"
              placeholder="Enter PhoneNumber..."
              name="phone"
              value={enrollUpdateData.phone}
              onChange={handleChange}
              error={!!studenterrors.phone}
              sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })}
            />
          </GradientBorder>
          {studenterrors.phone && (
    <VuiTypography variant="caption" color="error" mt={1}>
      {studenterrors.phone}
    </VuiTypography>
  )}
          </VuiBox>
        </Grid>
       {/* end */}
        

       {/* sixth col */}
       <Grid xs={2} sm={4} md={4}>
        <VuiBox mb={1} ml={2}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              Language
            </VuiTypography>
            <GradientBorder 
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
             {/* <VuiInput
              type="text"
              placeholder="Category..."
              sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })}
              
            /> */}
              <Select 
              sx={{
                backgroundColor:"#0f1535 !important",
                color:"white !important",
                borderRadius:"0.975rem !important"
              }}

         minWidth="100%"
          value={enrollUpdateData.language}
          onChange={handleLanguage}
        >
          <MenuItem value={"Tamil"}>Tamil</MenuItem>
          <MenuItem value={"English"}>English</MenuItem>
          
        </Select>
           
          </GradientBorder>
          {studenterrors.language && (
    <VuiTypography variant="caption" color="error" mt={1}>
      {studenterrors.language}
    </VuiTypography>
  )}
          </VuiBox>
        </Grid>
       {/* end */}

       

       <Grid xs={2} sm={4} md={4} >
        <VuiBox mb={1} ml={2}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
            Course
            </VuiTypography>
            <GradientBorder
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
             {/* <VuiInput
              type="text"
              placeholder="Courses..."
              sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })}
            /> */}
             <Select 
              sx={{
                backgroundColor:"#0f1535 !important",
                color:"white !important",
                borderRadius:"0.975rem !important"
              }}

         minWidth="100%"
          value={enrollUpdateData.course}
            //  value={course}
          onChange={handleCourses}
          error={!!studenterrors.course}
        >
          {/* <MenuItem value={"Mern Stack"}>Mern Stack</MenuItem>
          <MenuItem value={"UI/UX"}>UI/UX</MenuItem> */}
          <MenuItem value="" disabled>
                      Select Course 
                    </MenuItem>
                    {courseName.map((type, index) => (
                      <MenuItem key={index} value={type}>
                        {type}
                      </MenuItem>
                    ))}
        </Select>
           
          </GradientBorder>
          {studenterrors.course && (
    <VuiTypography variant="caption" color="error" mt={1}>
      {studenterrors.course}
    </VuiTypography>
  )}
          </VuiBox>
        </Grid>

        {/* <Grid xs={2} sm={4} md={4} >
        <VuiBox mb={1} ml={2}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
            RewardPoints
            </VuiTypography>
            <GradientBorder
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
             <VuiInput
              type="text"
              placeholder="rewardPoints..."
              name="rewardPoints"
              value={enrollUpdateData.rewardPoints}
              onChange={handleChange}
              sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })}
            />
          </GradientBorder>
          </VuiBox>
        </Grid> */}

        {/* <Grid xs={2} sm={4} md={4} >
        <VuiBox mb={1} ml={2}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
            FreeLancer
            </VuiTypography>
            <GradientBorder
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
            
            <Select 
              sx={{
                backgroundColor:"#0f1535 !important",
                color:"white !important",
                borderRadius:"0.975rem !important"
              }}

         minWidth="100%"
          value={enrollUpdateData.freelancer}
          onChange={handleFreeLancer}
        >
          <MenuItem value={"yes"}>Yes</MenuItem>
          <MenuItem value={"no"}>No</MenuItem>
          
        </Select>
          </GradientBorder>
          </VuiBox>
        </Grid> */}

        {/* submit */}

        
      </Grid>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} justifyContent="center">
  <Grid item xs={12} sm={8} md={4}>
    <VuiBox mt={5} mb={1}>
      <VuiButton color="info" fullWidth type="submit">
        Update
      </VuiButton>
    </VuiBox>
  </Grid>
</Grid>
</VuiBox>
    </Box>



     
      <Footer />
    </DashboardLayout>
  );
}

export default UpdateStudent;
