
import { useState } from "react";

// react-router-dom components
import { Link, useHistory } from "react-router-dom";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import GradientBorder from "examples/GradientBorder";

// Vision UI Dashboard assets
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgSignIn from "assets/images/signInImage.png";
import { useDispatch, useSelector } from "react-redux";
// import {login} from 'UserSlice/Login';
import axios from "axios";
import { Login } from "../../../redux/slices/LoginSlice";
import { API_Host } from "Api_utils";
import { valid } from "chroma-js";

function SignIn() {
  const [rememberMe, setRememberMe] = useState(true);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

//   const users = useSelector(state => state.user.user);
// console.log(users);
  const [email,setEmail] = useState("");
  const [password,setPassword] = useState("");
  const history = useHistory();
  const [validate, setValidate] = useState({ email: "", password: "" }); 
  const [hasSubmitted, setHasSubmitted] = useState(false);
  
  const dispatch = useDispatch();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setValidate((prevValidate) => ({ ...prevValidate, email: "" }));
  };


  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setValidate((prevValidate) => ({ ...prevValidate, password: "" }));
  };

// console.log(email)

 const handleLogin = async (e) => {
e.preventDefault();
setHasSubmitted(true);
const validateForm = validateLogin()
if(!validateForm){
  return
}
try {
  const res = await axios.post(`${API_Host}/login/`,{email,password})
  const role = res.data.role;
  const token = res.data.token;
  const course = res.data.course;
  localStorage.setItem("token",token);
  localStorage.setItem("role",role);
  localStorage.setItem('course',course);
  localStorage.setItem('email',email)

   
  dispatch(Login(res.data));
  console.log(res.data)
  console.log(role);
  history.push('/dashboard');
}
catch(err){
  if(err.response && err.response.status === 401){
    setValidate({
      email:"Invalid Email",
      password:"Incorrect Password"
    })

  }
  else{
    console.log(err)
  }
 
}
 }

const validateLogin = () =>{
  const newError = {}

  if(!email.trim()) {
    newError.email = "Email is required"
  }

  if(!password.trim()){
    newError.password = "Password is required"
  }

  setValidate(newError);

  return Object.keys(newError).length === 0;
}
 
 
  return (
    <CoverLayout
      title="Nice to see you!"
      color="white"
      description="Enter your email and password to sign in"
      premotto="INSPIRED BY THE FUTURE:"
      motto="CAREER LINES"
      image={bgSignIn}
    >
      <VuiBox component="form" role="form" onSubmit={handleLogin}>
        <VuiBox mb={2}>
          <VuiBox mb={1} ml={0.5}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              Email
            </VuiTypography>
          </VuiBox>
          <GradientBorder
            minWidth="100%"
            padding="1px"
            borderRadius="lg"
            backgroundImage="radial-gradient(circle, #gradientColors)"
          >
            <VuiInput 
            type="email"
            placeholder="Your email..."
            value={email}
            // onChange={(e) => setEmail(e.target.value)}
            // error={!!validate.email}
            onChange={handleEmailChange}
            error={hasSubmitted && !!validate.email}
            fontWeight="500"
           />
          </GradientBorder>
          {validate.email && (
                  <VuiTypography variant="caption" color="error" mt={1}>
                    {validate.email}
                  </VuiTypography>
                )}
        </VuiBox>
        <VuiBox mb={2}>
          <VuiBox mb={1} ml={0.5}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              Password
            </VuiTypography>
          </VuiBox>
          <GradientBorder
            minWidth="100%"
            padding="1px"
            borderRadius="lg"
            backgroundImage="radial-gradient(circle, #gradientColors)"
          >
            <VuiInput
              type="password"
              placeholder="Your password..."
              value={password}
              // onChange={(e) => setPassword(e.target.value)}
              onChange={handlePasswordChange}
              // error={!!validate.password}
              error={hasSubmitted && !!validate.password} 
              sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })}
            />
          </GradientBorder>
          {validate.password && (
                  <VuiTypography variant="caption" color="error" mt={1}>
                    {validate.password}
                  </VuiTypography>
                )}
        </VuiBox>
        {/* <VuiBox display="flex" alignItems="center">
          <VuiSwitch color="info" checked={rememberMe} onChange={handleSetRememberMe} />
          <VuiTypography
            variant="caption"
            color="white"
            fontWeight="medium"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none" }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;Remember me
          </VuiTypography>
        </VuiBox> */}
        <VuiBox mt={4} mb={1}>
          <VuiButton color="info" fullWidth type="submit">
            SIGN IN
          </VuiButton>
        </VuiBox>
        {/* <VuiBox mt={3} textAlign="center">
          <VuiTypography variant="button" color="text" fontWeight="regular">
            Don&apos;t have an account?{" "}
            <VuiTypography
              component={Link}
              to="/authentication/sign-up"
              variant="button"
              color="white"
              fontWeight="medium"
            >
              Sign up
            </VuiTypography>
          </VuiTypography>
        </VuiBox> */}
      </VuiBox>
    </CoverLayout>
  );
}

export default SignIn;
