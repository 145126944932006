
import * as React from 'react';
import Card from "@mui/material/Card";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import GradientBorder from "examples/GradientBorder";

// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import borders from "assets/theme/base/borders";
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import Grid from '@mui/material/Grid';
import { Box } from "@mui/material";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useState } from "react";
import 'app.css'
import { Password, Upload } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';

import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import { API_Host } from 'Api_utils';
// import {updateAssignTask } from '../../redux/slices/AssignTaskSlice';
import Swal from "sweetalert2";
import { updateAssignTask } from '../../../redux/slices/AssignTaskSlice';
function EditAssignTask() {

    const {id}= useParams();
    console.log(id);
 
  const dispatch = useDispatch();
  const history = useHistory ();
  // const testt = useSelector(state=>state.assignTask.assignTask);
  // console.log(testt);
  const assignData = useSelector(state=>state.assignTask.allAssignTask);
  console.log(assignData);
  const assignstudentTask = assignData.find(assignData => assignData.id === id);
  console.log(assignData.find(assignData => assignData.id === id));
//   const status = [{incomplete : 'incomplete'},{complete: 'complete'}]
//   console.log(taskData);
//   console.log(studentTask)
  
  // validation state
  // console.log(assignstudentTask);
  // console.log(assignData);
  // const rest = assignData.find(assignData => assignData.id === id);
  // console.log(rest);
const [taskerror,setTaskError] = useState({})
 const token = localStorage.getItem('token');
  const [taskUpdateData, setTaskUpdateData] = useState({
//     taskName:studentTask ? studentTask.taskName:"",
//     taskType :studentTask ? studentTask.taskType:"",
//    fileData: studentTask ? studentTask.fileData:""
 status : assignstudentTask ? assignstudentTask.status : "",

  
  });
console.log(taskUpdateData.status);
console.log(assignstudentTask.status)
 const statusArr = ['incomplete','complete']

// get call in rewardtype
//   const [rewardType,setRewardType] = useState([]);
//   React.useEffect(() => {
//     const fetchRewardTypes = async () => {
//       try {
//         const response = await axios.get(`${API_Host}/get-rewards`);
//         setRewardType(response.data.data.map(reward => reward.rewardType));
//       } catch (error) {
//         console.error("Error fetching reward types:", error);
//       }
//     };

//     fetchRewardTypes();
//   }, []);

  // update call
const handleUpdateTask = async (e) => {
    e.preventDefault();

    const taskValid = await taskValidate()
    if(!taskValid){
      return
    }
    // Create FormData object
    // const formData = new FormData();
    // formData.append('taskName', taskUpdateData.taskName);
    // formData.append('taskType', taskUpdateData.taskType);
   

    // // Append file only if it's selected
    // if (taskUpdateData.fileData) {
    //     formData.append('file', taskUpdateData.fileData);
    // }
    const data = {
      status: taskUpdateData.status
    }

    try {
        const response = await axios.put(`${API_Host}/submitAssignedTaskFor-student/${id}`, data,{
          headers: 
          { 
          "Authorization": `Bearer ${token}`, // Include the token in the Authorization header
          }
        });
        dispatch(updateAssignTask(response.data.data));
        console.log("Task updated Successfully", response.data.data);


        console.log(assignstudentTask.assigned)
       
     

        
        Swal.fire({
          title: "Task Edited Successfully!",
          text: "Your Task has been Edited.",
          icon: "success",
          showClass: {
            popup: `
              animate__animated
              animate__fadeInUp
              animate__faster
            `
          },
          hideClass: {
            popup: `
              animate__animated
              animate__fadeOutDown
              animate__faster
            `
          }
        });
   history.push('/view-assigned-task');
    } 
    catch (err) {
        console.error("Error updateing task:", err);
    }
    const email = assignstudentTask.assigned;
    const reqData = {rewardPoints: assignstudentTask.rewardPoints};
    console.log(reqData);
    try{
      const response = await axios.put(`${API_Host}/reward-edit-user/${email}`,reqData,{
        headers:{
          Authorization: `Bearer ${token}`
        }
      })
      console.log(response);
  }
  catch(err){
    console.error(err)
  }
};
// console.log(assignstudentTask.taskType);

//   

const handleChange = (event) => {
    const { name, value, files } = event.target;

    setTaskUpdateData((prevFormData) => ({
        ...prevFormData,
        [name]: files ? files[0] : value, 
    }));
    const error = {...taskerror};
    if(value.trim() === ""){
    error[name] =``;
    
    }
    else{
      delete error[name]
    }
    setTaskError(error)
      
};

const taskValidate  = async() =>{
  const error = {}
  console.log(error)
  if(!taskUpdateData.status) error.taskName = 'status is required'
  
  setTaskError(error)
  return Object.keys(error).length === 0;
   }

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Box sx={{ width: '100%' ,height:'100vh'}} mt={15}  >
        <VuiBox component="form" role="form" onSubmit={handleUpdateTask}>
        {/* first col */}
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
       <Grid xs={2} sm={4} md={4}>
        <VuiBox mb={1} ml={2}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
           Status
            </VuiTypography>
            <GradientBorder
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
             {/* <VuiInput
              type="text"
              placeholder="Enter Type..."
              name='taskType'
              value={addTask.taskType}
              onChange={handleChange}
              sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })}
            /> */}

<Select
                    name="status"
                    value={taskUpdateData.status}
                    onChange={handleChange}
                    error={!!taskerror.status}
                    displayEmpty
                    fullWidth
                    sx={{
                        backgroundColor:"#0f1535 !important",
                        color:"white !important",
                        borderRadius:"0.975rem !important"
                      }}
                  >
                    <MenuItem value="" disabled>
                      Select status
                    </MenuItem>
                    {statusArr.map((type, index) => (
                      <MenuItem key={index} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
          </GradientBorder>
          {taskerror.taskType && (
            <VuiTypography variant="caption" color="error" mt={1}>
              {taskerror.taskType}
            </VuiTypography>
          )}
          </VuiBox>
        </Grid>
{/* end */}

{/* second col */}
        {/* <Grid xs={2} sm={4} md={4}>
        <VuiBox mb={1} ml={2}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
           TaskType
            </VuiTypography>
            <GradientBorder
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
           

<Select
                    name="taskType"
                    value={taskUpdateData.taskType}
                    onChange={handleChange}
                    error={!!taskerror.taskType}
                    displayEmpty
                    fullWidth
                    sx={{
                        backgroundColor:"#0f1535 !important",
                        color:"white !important",
                        borderRadius:"0.975rem !important"
                      }}
                  >
                    <MenuItem value="" disabled>
                      Select Task Type
                    </MenuItem>
                    {rewardType.map((type, index) => (
                      <MenuItem key={index} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
          </GradientBorder>
          {taskerror.taskType && (
            <VuiTypography variant="caption" color="error" mt={1}>
              {taskerror.taskType}
            </VuiTypography>
          )}
          </VuiBox>
        </Grid> */}
       {/* end */}

       {/* <Grid xs={2} sm={4} md={4}>
        <VuiBox mb={1} ml={2}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
           Upload File
            </VuiTypography>
            <GradientBorder
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
             <VuiInput
              type="file"
              placeholder="uploadfile..."
              name='fileData'
              value={taskUpdateData.file}
              error={!!taskerror.fileData}
              onChange={handleChange}
              sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })}
            />
          </GradientBorder>
          {taskerror.fileData && (
            <VuiTypography variant="caption" color="error" mt={1}>
              {taskerror.fileData}
            </VuiTypography>
          )}
          </VuiBox>
        </Grid> */}
        
      </Grid>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} justifyContent="center">
  <Grid item xs={12} sm={8} md={4}>
    <VuiBox mt={5} mb={1}>
      <VuiButton color="info" fullWidth type="submit">
        Update
      </VuiButton>
    </VuiBox>
  </Grid>
</Grid>
</VuiBox>
    </Box>



     
      <Footer />
    </DashboardLayout>
  );
}

export default EditAssignTask;