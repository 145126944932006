
import Card from "@mui/material/Card";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import axios from "axios";
import { deleteEnrollStudent, getEnrollStudent } from "../../../src/redux/slices/EnrollSlice";
import UpgradeSharpIcon from "@mui/icons-material/UpgradeSharp";
import { Link, useHistory } from "react-router-dom";

// // Data
// import authorsTableData from "layouts/tables/data/authorsTableData";
// import projectsTableData from "layouts/tables/data/projectsTableData";
import { Button, Grid } from "@mui/material";
// host
import { API_Host } from "Api_utils";
import UpdateStudent from "./UpdateStudent";
// import { useNavigate,Link } from 'react-router-dom';
// import { useHistory,Link } from "react-router-dom/cjs/react-router-dom.min";
import VuiButton from "components/VuiButton";
import Swal from "sweetalert2";
import "../../../src/app.css";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import VuiInput from "components/VuiInput";

function ViewEnrollStudent() {
  // const { columns, rows } = authorsTableData;
  // const { columns: prCols, rows: prRows } = projectsTableData;

  const dispatch = useDispatch();
  const student = useSelector((state) => state.enrollStudent.enrollStudent);
  // const navigate = useNavigate();
  const history = useHistory();
  // console.log(student);

  // filter student
  const [searchStudent,setSearchStudent] = useState('');

  const filteredStudentData = student.filter(
    (student) =>
      (student.name && student.name.toLowerCase().includes(searchStudent.toLowerCase())) ||
      (student.email && student.email.toLowerCase().includes(searchStudent.toLowerCase())) ||
      (student.course && student.course.toLowerCase().includes(searchStudent.toLowerCase()))
  );
  
  const studentData = async () => {
    try {
      const role = "student";
      const response = await axios.get(`${API_Host}/get-user-role/${role}`);
      dispatch(getEnrollStudent(response.data.data));
      console.log("data fetched successfully", response.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    studentData();
  }, []);

  // delete call
  const handleEnrollStudentDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to delete this Student? This action cannot be undone.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    })
      .then((quizResult) => {
        if (quizResult.isConfirmed) {
          Swal.fire({
            title: "Deleted!",
            text: "Your Student has been deleted.",
            icon: "success",
          });
          axios.delete(`${API_Host}/delete-user/` + id).then((res) => {
            dispatch(deleteEnrollStudent({ id }));
            studentData();
            console.log("deleted student successfully", student);
          });
        }
      })

      .catch((err) => console.log(err));
  };
  // // update call
  // const handleStudentEdit = () => {

  // history.push('/update-student')
  // }

  const columns = [
    { name: "Name", align: "left" },
    // { name: "password", align: "left" },
    { name: "Email", align: "left" },
    { name: "Phone", align: "left" },
    { name: "role", align: "center" },
    { name: "Language", align: "center" },
    { name: "Courses", align: "center" },
    // { name: "freelancer", align: "center" },
    // { name: "rewardPoints", align: "center" },
    // { name: "action", align: "center" },
    { name: "edit", align: "center" },
    { name: "delete", align: "center" },
  ];

  // Map over students to create the rows
  const rows = filteredStudentData.map((student) => ({
    Name: student.name,
    // password:student.password,
    Email: student.email,
    Phone: student.phone,
    Language: student.language,
    Courses: student.course,
    role: student.role,
    freelancer: student.freelancer,
    rewardPoints: student.rewardPoints,
    edit: (
      <>
        <Link to={`/update-student/${student.id}`}>
          {/* <Button >Edit</Button> */}
          <EditIcon fontSize="small" className="editIcon"></EditIcon>
        </Link>
        {/* <Button onClick={handleStudentEdit}>Edit</Button> */}
      </>
    ),

    delete: (
      <>
        {/* <Button onClick={() => handleEnrollStudentDelete(student.id)}>Delete</Button> */}
        <DeleteIcon
          onClick={() => handleEnrollStudentDelete(student.id)}
          className="deleteIcon"
          fontSize="small"
        ></DeleteIcon>
      </>
      // <UpgradeSharpIcon
      //   sx={{
      //     fontSize: "20px !important"
      //   }}
      // >

      // </UpgradeSharpIcon>
    ),
  }));

  console.log("Rows data:", rows);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3} sx={{height:'100vh'}}>
        <VuiBox mb={8}></VuiBox>
        {/* <Link  to={`/add-student`} >
      <Button sx={{marginLeft:"10px"}}>AddQuiz</Button>
      <VuiButton sx={{marginBottom:"15px",marginLeft:"15px"}} variant="contained" color="info">Add Student</VuiButton>
      </Link> */}
         <VuiBox style={{ width: "25%", marginLeft: "20px",marginBottom:'25px' }}>
            <VuiInput
              placeholder="Type here..."
              icon={{ component: "search", direction: "left" }}
              sx={({ breakpoints }) => ({
                [breakpoints.down("sm")]: {
                  maxWidth: "80px",
                },
                [breakpoints.only("sm")]: {
                  maxWidth: "80px",
                },
                backgroundColor: "info.main !important",
              
              })}
              value={searchStudent}
              onChange={(e) => setSearchStudent(e.target.value)}
            />
          </VuiBox>
        <Card>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
            <VuiBox display="flex" justifyContent="space-between" alignItems="center">
            <VuiTypography
              variant="lg"
              color="white"
              style={{ marginLeft: "8px", marginBottom: "15px" }}
            >
              View Enroll Student
            </VuiTypography>
          </VuiBox>
            </Grid>
            <Grid item xs={6}>
            <div className="bttn">
            <Link to={`/add-student`} className="link">
              <VuiButton sx={{ marginBottom: "15px" }} variant="contained" color="info">
                <AddIcon style={{ marginRight: "10px" }}></AddIcon>
                Add Student
              </VuiButton>
            </Link>
          </div>
            </Grid>
          </Grid>
         

          
          <VuiBox
            sx={{
              "& th": {
                borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                  `${borderWidth[1]} solid ${grey[700]}`,
              },
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                    `${borderWidth[1]} solid ${grey[700]}`,
                },
              },
            }}
          >
            <Table columns={columns} rows={rows} />
          </VuiBox>
        </Card>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ViewEnrollStudent;
