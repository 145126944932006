import React from "react";
import PropTypes from "prop-types";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiBadge from "components/VuiBadge";
import { useTimeline } from "examples/Timeline/context";
import { timelineItem } from "examples/Timeline/TimelineItem/styles";

// Example medal icon URLs
const goldMedal = 'https://cdn-icons-png.flaticon.com/512/7322/7322858.png';
const silverMedal = 'https://cdn-icons-png.flaticon.com/512/7645/7645682.png';
const bronzeMedal = 'https://cdn3d.iconscout.com/3d/premium/thumb/bronze-medal-3d-icon-download-in-png-blend-fbx-gltf-file-formats--tag-rating-label-reward-and-badges-pack-team-sports-icons-6878283.png';

function TimelineItem({ color, icon, name, rank, rewardPoints, title, dateTime, description, badges, lastItem }) {
  const isDark = useTimeline();

  const renderBadges =
    badges.length > 0
      ? badges.map((badge, key) => {
          const badgeKey = `badge-${key}`;

          return (
            <VuiBox key={badgeKey} mr={key === badges.length - 1 ? 0 : 0.5}>
              <VuiBadge color={color} size="xs" badgeContent={badge} container />
            </VuiBox>
          );
        })
      : null;

  // Function to return the appropriate medal icon based on rank
  const renderMedalIcon = (rank) => {
    switch (rank) {
      case 1:
        return <img src={goldMedal} alt="Gold Medal" width="40" style={{ marginRight: '8px' }} />;
      case 2:
        return <img src={silverMedal} alt="Silver Medal" width="40" style={{ marginRight: '8px' }} />;
      case 3:
        return <img src={bronzeMedal} alt="Bronze Medal" width="40" style={{ marginRight: '8px' }} />;
      // default:5
      //   return icon;  // For ranks beyond 3, show the default icon (e.g., a bell or anything else passed as a prop)
    }
  };

  return (
    <VuiBox position="relative" mb="24px" sx={(theme) => timelineItem(theme, { color })}>
      <VuiBox
        width="1.625rem"
        height="1.625rem"
        borderRadius="50%"
        position="absolute"
        top="3.25%"
        left="-8px"
        zIndex={2}
      >
        {/* Render medal icon based on rank */}
        {renderMedalIcon(rank)}
      </VuiBox>
      <VuiBox ml="30px" pt={description ? 0.7 : 0.5} lineHeight={0} maxWidth="30rem">
        <VuiTypography variant="button" fontWeight="medium" color="white">
          {rank}. {name}
        </VuiTypography>
        <VuiBox mt={0.5}>
          <VuiTypography variant="caption" fontWeight="medium" color="text">
            {rewardPoints} 
          </VuiTypography>
        </VuiBox>
        {/* <VuiBox mt={2} mb={1.5}>
          {description ? (
            <VuiTypography variant="button" fontWeight="regular" color="text">
              {description}
            </VuiTypography>
          ) : null}
        </VuiBox>
        {badges.length > 0 ? (
          <VuiBox display="flex" pb={lastItem ? 1 : 2}>
            {renderBadges}
          </VuiBox>
        ) : null} */}
      </VuiBox>
    </VuiBox>
  );
}

// Setting default values for the props of TimelineItem
TimelineItem.defaultProps = {
  color: "info",
  badges: [],
  lastItem: false,
  description: "",
};

// Typechecking props for the TimelineItem
TimelineItem.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  rank: PropTypes.number.isRequired,
  rewardPoints: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  dateTime: PropTypes.string.isRequired,
  description: PropTypes.string,
  badges: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  lastItem: PropTypes.bool,
};

export default TimelineItem;
