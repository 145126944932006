import { createSlice } from "@reduxjs/toolkit";
export const userSlice = createSlice({
    name:"user",
    initialState:{
        user:null, 
        role:""
    },
    reducers:{
        Login:(state,action) => {
            state.user = action.payload.user;
            state.role = action.payload.role;
            console.log(state.role);

        }
    }
});

export const {Login} = userSlice.actions;

// export const selectUser = (state) => state.user.user;

export default userSlice.reducer;