

import React from "react";
import { createRoot} from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import store  from "./redux/Store";
import {Provider} from 'react-redux';
import{PersistGate} from 'redux-persist/integration/react';
import {persistStore} from 'redux-persist';

// Vision UI Dashboard React Context Provider
import { VisionUIControllerProvider } from "context";

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
const persistor = persistStore(store);

root.render(<BrowserRouter>
  <VisionUIControllerProvider>
  <Provider store={store}>
    <PersistGate persistor={persistor} >
    <App />
    </PersistGate>
    </Provider>
  </VisionUIControllerProvider>
</BrowserRouter>)

