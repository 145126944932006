
import * as React from 'react';
import Card from "@mui/material/Card";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import GradientBorder from "examples/GradientBorder";

// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import borders from "assets/theme/base/borders";
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import Grid from '@mui/material/Grid';
import { Box } from "@mui/material";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useState } from "react";
import 'app.css'
import { Password, Upload } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';

import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import { API_Host } from 'Api_utils';
import {updateTask } from '../../redux/slices/TaskSlice';
import Swal from "sweetalert2";

function UpdateTask() {

    const {id}= useParams();
 
  const dispatch = useDispatch();
  const history = useHistory ();

  const taskData = useSelector(state=>state.task.task);
  const studentTask = taskData.find(taskData => taskData.id === id);
  console.log(taskData);
  console.log(studentTask)
  
  // validation state
const [taskerror,setTaskError] = useState({})
 
  const [taskUpdateData, setTaskUpdateData] = useState({
    taskName:studentTask ? studentTask.taskName:"",
    taskType :studentTask ? studentTask.taskType:"",
   fileData: studentTask ? studentTask.fileData:""
  
  });
console.log(taskUpdateData)

// get call in rewardtype
  const [rewardType,setRewardType] = useState([]);
  React.useEffect(() => {
    const fetchRewardTypes = async () => {
      try {
        const response = await axios.get(`${API_Host}/get-rewards`);
        setRewardType(response.data.data.map(reward => reward.rewardType));
      } catch (error) {
        console.error("Error fetching reward types:", error);
      }
    };

    fetchRewardTypes();
  }, []);

  // update call
const handleUpdateTask = async (e) => {
    e.preventDefault();

    const taskValid = await taskValidate()
    if(!taskValid){
      return
    }
    // Create FormData object
    const formData = new FormData();
    formData.append('taskName', taskUpdateData.taskName);
    formData.append('taskType', taskUpdateData.taskType);
   

    // Append file only if it's selected
    if (taskUpdateData.fileData) {
        formData.append('file', taskUpdateData.fileData);
    }

    try {
        const response = await axios.put(`${API_Host}/edit-task/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        dispatch(updateTask(response.data.data));
        console.log("Task updated Successfully", response.data.data);

        Swal.fire({
          title: "Task Edited Successfully!",
          text: "Your Task has been Edited.",
          icon: "success",
          showClass: {
            popup: `
              animate__animated
              animate__fadeInUp
              animate__faster
            `
          },
          hideClass: {
            popup: `
              animate__animated
              animate__fadeOutDown
              animate__faster
            `
          }
        });
        history.push('/task');
    } catch (err) {
        console.error("Error updateing task:", err);
    }
};

//   

const handleChange = (event) => {
    const { name, value, files } = event.target;

    setTaskUpdateData((prevFormData) => ({
        ...prevFormData,
        [name]: files ? files[0] : value, 
    }));
    const error = {...taskerror};
    if(value.trim() === ""){
    error[name] =``;
    
    }
    else{
      delete error[name]
    }
    setTaskError(error)
      
};

const taskValidate  = async() =>{
  const error = {}
  console.log(error)
  if(!taskUpdateData.taskName) error.taskName = 'TaskName is required'
  if(!taskUpdateData.taskType) error.taskType = 'TaskType is required'
  if(!taskUpdateData.fileData) error.fileData = "uploadfile is required"
  
  setTaskError(error)
  return Object.keys(error).length === 0;
   }

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Box sx={{ width: '100%',height:'100vh' }} mt={10} >
        <VuiBox component="form" role="form" onSubmit={handleUpdateTask}>
        {/* first col */}
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid  xs={2} sm={4} md={4}>
        <VuiBox mb={1} ml={2}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
           
              TaskName
            </VuiTypography>
            <GradientBorder
            minWidth="100%"
            padding="1px"
            borderRadius={borders.borderRadius.lg}
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
            {/* <VuiInput type="text" placeholder="Enter Your Courses..." fontWeight="500"
            name='rewardType'
            value={rewardsData.rewardType}
            onChange={handleChange}
             /> */}

<VuiInput
              type="text"
              placeholder="Enter Task..."
              name='taskName'
              value={taskUpdateData.taskName}
              onChange={handleChange}
              error={!!taskerror.taskName}
              sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })}
            />
          </GradientBorder>
          {taskerror.taskName && (
            <VuiTypography variant="caption" color="error" mt={1}>
              {taskerror.taskName}
            </VuiTypography>
          )}
        </VuiBox>
        </Grid>
{/* end */}

{/* second col */}
        <Grid xs={2} sm={4} md={4}>
        <VuiBox mb={1} ml={2}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
           TaskType
            </VuiTypography>
            <GradientBorder
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
             {/* <VuiInput
              type="text"
              placeholder="Enter Type..."
              name='taskType'
              value={addTask.taskType}
              onChange={handleChange}
              sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })}
            /> */}

<Select
                    name="taskType"
                    value={taskUpdateData.taskType}
                    onChange={handleChange}
                    error={!!taskerror.taskType}
                    displayEmpty
                    fullWidth
                    sx={{
                        backgroundColor:"#0f1535 !important",
                        color:"white !important",
                        borderRadius:"0.975rem !important"
                      }}
                  >
                    <MenuItem value="" disabled>
                      Select Task Type
                    </MenuItem>
                    {rewardType.map((type, index) => (
                      <MenuItem key={index} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
          </GradientBorder>
          {taskerror.taskType && (
            <VuiTypography variant="caption" color="error" mt={1}>
              {taskerror.taskType}
            </VuiTypography>
          )}
          </VuiBox>
        </Grid>
       {/* end */}

       <Grid xs={2} sm={4} md={4}>
        <VuiBox mb={1} ml={2}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
           Upload File
            </VuiTypography>
            <GradientBorder
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
             <VuiInput
              type="file"
              placeholder="uploadfile..."
              name='fileData'
              value={taskUpdateData.file}
              error={!!taskerror.fileData}
              onChange={handleChange}
              sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })}
            />
          </GradientBorder>
          {taskerror.fileData && (
            <VuiTypography variant="caption" color="error" mt={1}>
              {taskerror.fileData}
            </VuiTypography>
          )}
          </VuiBox>
        </Grid>
        
      </Grid>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} justifyContent="center">
  <Grid item xs={12} sm={8} md={4}>
    <VuiBox mt={5} mb={1}>
      <VuiButton color="info" fullWidth type="submit">
        Update
      </VuiButton>
    </VuiBox>
  </Grid>
</Grid>
</VuiBox>
    </Box>



     
      <Footer />
    </DashboardLayout>
  );
}

export default UpdateTask;