import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import { Card, LinearProgress, Stack } from "@mui/material";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiProgress from "components/VuiProgress";

// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import linearGradient from "assets/theme/functions/linearGradient";

// Vision UI Dashboard React base styles
import typography from "assets/theme/base/typography";
import colors from "assets/theme/base/colors";

// Dashboard layout components
import WelcomeMark from "layouts/dashboard/components/WelcomeMark";
import Projects from "layouts/dashboard/components/Projects";
import OrderOverview from "layouts/dashboard/components/OrderOverview";
import SatisfactionRate from "layouts/dashboard/components/SatisfactionRate";
import ReferralTracking from "layouts/dashboard/components/ReferralTracking";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";

// React icons
import { IoIosRocket } from "react-icons/io";
import { IoGlobe } from "react-icons/io5";
import { IoBuild } from "react-icons/io5";
import { IoWallet } from "react-icons/io5";
import { IoDocumentText } from "react-icons/io5";
import { FaShoppingCart } from "react-icons/fa";

// Data
import LineChart from "examples/Charts/LineCharts/LineChart";
import BarChart from "examples/Charts/BarCharts/BarChart";
import { lineChartDataDashboard } from "layouts/dashboard/data/lineChartData";
import { lineChartOptionsDashboard } from "layouts/dashboard/data/lineChartOptions";
import { barChartDataDashboard } from "layouts/dashboard/data/barChartData";
import { barChartOptionsDashboard } from "layouts/dashboard/data/barChartOptions";
import PersonIcon from "@mui/icons-material/Person";
import {getCoursesCount } from "../../redux/slices/CourseSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import axios from "axios";
import { API_Host } from "Api_utils";
import { getInstructorCount, getStudentCount } from "../../redux/slices/EnrollSlice";
import { getAssignCount, getCompletedTaskStatus, getIncompleteTaskStatus} from "../../redux/slices/AssignTaskSlice";
import { getQuizCount } from "../../redux/slices/QuizSlice";

function Dashboard() {
  const { gradients } = colors;
  const { cardContent } = gradients;

  const token = localStorage.getItem("token");
  const userRole = localStorage.getItem("role");
  const email = localStorage.getItem('email');
  console.log(email);
  // course count selector
  const courseData = useSelector(state => state.course.courseCount);
  const student = useSelector((state) => state.enrollStudent.studentCount);
  const instructorCount = useSelector((state) => state.enrollStudent.instructorCount);
  const test = useSelector((state) => state.assignTask.assignCount);
  const completedtask = useSelector((state) => state.assignTask.completedTaskCount);
  const incompletedtask = useSelector((state) => state.assignTask.incompleteTaskCount);
  const quizData = useSelector((state) => state.quiz.quizCount);

  const dispatch = useDispatch();

  console.log(courseData);
  console.log(student);
  console.log(instructorCount);
  console.log(test);
  console.log(completedtask);
  console.log(incompletedtask);
  console.log(quizData);

// get call for course count
const fetchCourse = async() =>{
  try{
    const res= await axios.get(`${API_Host}/get-course`)
    dispatch(getCoursesCount(res.data.data.length))
    console.log("fetch quiz data",res.data.data.length)
  }
  catch(err){
    console.log(err)
  }
    }
useEffect(()=>{
  fetchCourse();
},[])

// get call for student count
const studentData = async () => {
  try {
    const role = "student";
    const response = await axios.get(`${API_Host}/get-user-role/${role}`);
    dispatch(getStudentCount(response.data.data.length));
    console.log("data fetched successfully", response.data.data.length);
  } catch (err) {
    console.log(err);
  }
};

useEffect(() => {
  studentData();
}, []);

// get call for instructor count
const instructor = async () => {
  try {
    const role = "instructor";
    const response = await axios.get(`${API_Host}/get-user-role/${role}`);
    dispatch(getInstructorCount(response.data.data.length));
    console.log("data fetched successfully", response.data.data.length);
  } catch (err) {
    console.log(err);
  }
};

useEffect(() => {
  instructor();
}, []);

// get assign task count for student 
const fetchTask = async () => {
  try {
    const res = await axios.get(`${API_Host}/getAssignedTaskFor-student`, {
        headers: 
        { 
        "Authorization": `Bearer ${token}`, // Include the token in the Authorization header
        }
      });
    dispatch(getAssignCount(res.data.data.length));
    console.log(res.data.data.length);
  } catch (err) {
    console.error(err);
  }
};

useEffect(() => {
  
  fetchTask();
}, []);

// completetask count get call
useEffect(() => {
  const fetchSubmittedTask = async () => {
    try {
      const res = await axios.get(`${API_Host}/getAssignedTaskFor-student`, {
          headers: 
          { 
          "Content-Type": "multipart/form-data" ,
          "Authorization": `Bearer ${token}`, // Include the token in the Authorization header
          }
        });
        const allTask = res.data.data;
        const completedTask = allTask.filter(task =>task.status === 'complete').length;
      dispatch(getCompletedTaskStatus(completedTask));
      console.log(completedTask);
    } catch (err) {
      console.error(err);
    }
  };
  fetchSubmittedTask();
}, []);

useEffect(() => {
  const fetchTask = async () => {
    try {
      const res = await axios.get(`${API_Host}/getAssigned-task`, {
          headers: 
          { 
          "Content-Type": "multipart/form-data" ,
          "Authorization": `Bearer ${token}`, // Include the token in the Authorization header
          }
        });
        const allTask = res.data.data;
        const completedTask = allTask.filter(task =>task.status === 'incomplete').length;
      dispatch(getIncompleteTaskStatus(completedTask));
      console.log(completedTask);
    } catch (err) {
      console.error(err);
    }
  };
  fetchTask();
}, []);

// quizDatacount
const fetchQuiz = async () => {
  try {
    const res = await axios.get(`${API_Host}/get-quiz`,{
      headers: 
      { 
      "Content-Type": "multipart/form-data" ,
      "Authorization": `Bearer ${token}`, // Include the token in the Authorization header
      }
    });
    dispatch(getQuizCount(res.data.data.length));
    console.log("fetch quiz data", res.data.data.length);
  } catch (err) {
    console.log(err);
  }
};

useEffect(() => {
  fetchQuiz();
}, []);

  let cardData;
  if (userRole === "student") {
    cardData = [
      { title: "task", count: test ? test : 0 , icon: <AutoStoriesIcon /> },
      { title: "CompletedTask", count: completedtask ? completedtask :0, icon: <AutoStoriesIcon /> },
      { title: "Quiz", count: quizData ? quizData : 0, icon: <AutoStoriesIcon /> },
    ];
  } else if (userRole === "instructor") {
    cardData = [
      { title: "Student", count: student ? student: 0, icon: <PersonIcon /> },
      { title: "Course", count:courseData ? courseData: 0, icon: <PersonIcon /> },
      { title: "IncompleteTask", count: incompletedtask ? incompletedtask:0, icon: <PersonIcon /> },
    ];
  } else if (userRole === "admin") {
    cardData = [
      { title: "Student",count: student ? student: 0,icon: <PersonIcon /> },
      { title: "Instructor",count: instructorCount ? instructorCount: 0,icon: <PersonIcon /> },
      { title: "Course",count:courseData ? courseData: 0,icon: <PersonIcon /> },
    ];
  } else {
    console.log("data show failed");
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <VuiBox mb={3}>
          <Grid container spacing={3}>
            {cardData.map((card, index) => (
              <Grid item xs={12} md={6} xl={4} key={index}>
                <MiniStatisticsCard
                  title={{ text: card.title, fontWeight: "regular" }}
                 count={card.count}
                  icon={{ color: "info", component: card.icon }}
                />
              </Grid>
            ))}
            {/* <Grid item xs={12} md={6} xl={4}>
              <MiniStatisticsCard
                title={{ text: "Instructor" }}
                count="10"
                percentage={{ color: "success", text: "+3%" }}
                icon={{ color: "info", component: <PersonIcon size="22px" color="white" /> }}
              />
            </Grid> */}
            {/* <Grid item xs={12} md={6} xl={4}>
              
              <MiniStatisticsCard
                title={{ text: "Student Enrolled" }}
                count="50"
                // percentage={{ color: "error", text: "-2%" }}
                icon={{ color: "info", component: <PersonIcon size="22px" color="white" /> }}
              />
            </Grid> */}
            {/* <Grid item xs={12} md={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "total sales" }}
                count="$103,430"
                percentage={{ color: "success", text: "+5%" }}
                icon={{ color: "info", component: <FaShoppingCart size="20px" color="white" /> }}
              />
            </Grid> */}
          </Grid>
        </VuiBox>
        <VuiBox mb={3}>
          {/* <Grid container spacing="18px">
            <Grid item xs={12} lg={12} xl={5}>
              <WelcomeMark />
            </Grid>
            <Grid item xs={12} lg={6} xl={3}>
              <SatisfactionRate />
            </Grid>
            <Grid item xs={12} lg={6} xl={4}>
              <ReferralTracking />
            </Grid>
          </Grid> */}
        </VuiBox>
        <VuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12} xl={12}>
              <Card>
                <VuiBox sx={{ height: "100%" }}>
                  <VuiTypography variant="lg" color="white" fontWeight="bold" mb="5px">
                    {userRole === "student" && "Task Completed"}
                    {userRole === "instructor" && "Incomplete Task"}
                    {userRole === "admin" && "Student Enrolled"}
                  </VuiTypography>
                  <VuiBox display="flex" alignItems="center" mb="40px">
                    {/* <VuiTypography variant="button" color="success" fontWeight="bold">
                      +5% more{" "}
                      <VuiTypography variant="button" color="text" fontWeight="regular">
                        in 2021
                      </VuiTypography>
                    </VuiTypography> */}
                  </VuiBox>
                  <VuiBox sx={{ height: "310px" }}>
                    <LineChart
                      lineChartData={lineChartDataDashboard}
                      lineChartOptions={lineChartOptionsDashboard}
                    />
                  </VuiBox>
                </VuiBox>
              </Card>
            </Grid>
            {/* <Grid item xs={12} lg={6} xl={5}>
              <Card>
                <VuiBox>
                  <VuiBox
                    mb="24px"
                    height="220px"
                    sx={{
                      background: linearGradient(
                        cardContent.main,
                        cardContent.state,
                        cardContent.deg
                      ),
                      borderRadius: "20px",
                    }}
                  >
                    <BarChart
                      barChartData={barChartDataDashboard}
                      barChartOptions={barChartOptionsDashboard}
                    />
                  </VuiBox>
                  <VuiTypography variant="lg" color="white" fontWeight="bold" mb="5px">
                    Active Students
                  </VuiTypography>
                  <VuiBox display="flex" alignItems="center" mb="40px">
                    <VuiTypography variant="button" color="success" fontWeight="bold">
                      (+23){" "}
                      <VuiTypography variant="button" color="text" fontWeight="regular">
                        than last week
                      </VuiTypography>
                    </VuiTypography>
                  </VuiBox>
                  <Grid container spacing="50px">
                    <Grid item xs={6} md={3} lg={3}>
                      <Stack
                        direction="row"
                        spacing={{ sm: "10px", xl: "4px", xxl: "10px" }}
                        mb="6px"
                      >
                        <VuiBox
                          bgColor="info"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          sx={{ borderRadius: "6px", width: "25px", height: "25px" }}
                        >
                          <IoWallet color="#fff" size="12px" />
                        </VuiBox>
                        <VuiTypography color="text" variant="button" fontWeight="medium">
                          Users
                        </VuiTypography>
                      </Stack>
                      <VuiTypography color="white" variant="lg" fontWeight="bold" mb="8px">
                        32,984
                      </VuiTypography>
                      <VuiProgress value={60} color="info" sx={{ background: "#2D2E5F" }} />
                    </Grid>
                    <Grid item xs={6} md={3} lg={3}>
                      <Stack
                        direction="row"
                        spacing={{ sm: "10px", xl: "4px", xxl: "10px" }}
                        mb="6px"
                      >
                        <VuiBox
                          bgColor="info"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          sx={{ borderRadius: "6px", width: "25px", height: "25px" }}
                        >
                          <IoIosRocket color="#fff" size="12px" />
                        </VuiBox>
                        <VuiTypography color="text" variant="button" fontWeight="medium">
                          Clicks
                        </VuiTypography>
                      </Stack>
                      <VuiTypography color="white" variant="lg" fontWeight="bold" mb="8px">
                        2,42M
                      </VuiTypography>
                      <VuiProgress value={60} color="info" sx={{ background: "#2D2E5F" }} />
                    </Grid>
                    <Grid item xs={6} md={3} lg={3}>
                      <Stack
                        direction="row"
                        spacing={{ sm: "10px", xl: "4px", xxl: "10px" }}
                        mb="6px"
                      >
                        <VuiBox
                          bgColor="info"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          sx={{ borderRadius: "6px", width: "25px", height: "25px" }}
                        >
                          <FaShoppingCart color="#fff" size="12px" />
                        </VuiBox>
                        <VuiTypography color="text" variant="button" fontWeight="medium">
                          Sales
                        </VuiTypography>
                      </Stack>
                      <VuiTypography color="white" variant="lg" fontWeight="bold" mb="8px">
                        2,400$
                      </VuiTypography>
                      <VuiProgress value={60} color="info" sx={{ background: "#2D2E5F" }} />
                    </Grid>
                    <Grid item xs={6} md={3} lg={3}>
                      <Stack
                        direction="row"
                        spacing={{ sm: "10px", xl: "4px", xxl: "10px" }}
                        mb="6px"
                      >
                        <VuiBox
                          bgColor="info"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          sx={{ borderRadius: "6px", width: "25px", height: "25px" }}
                        >
                          <IoBuild color="#fff" size="12px" />
                        </VuiBox>
                        <VuiTypography color="text" variant="button" fontWeight="medium">
                          Items
                        </VuiTypography>
                      </Stack>
                      <VuiTypography color="white" variant="lg" fontWeight="bold" mb="8px">
                        320
                      </VuiTypography>
                      <VuiProgress value={60} color="info" sx={{ background: "#2D2E5F" }} />
                    </Grid>
                  </Grid>
                </VuiBox>
              </Card>
            </Grid> */}
          </Grid>
        </VuiBox>
        <Grid container spacing={3} direction="row" justifyContent="center" alignItems="stretch">
          <Grid item xs={12} md={6} lg={8}>
            <Projects />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <OrderOverview />
          </Grid>
        </Grid>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
