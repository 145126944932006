import {configureStore} from '@reduxjs/toolkit';
import userReducer from '../redux/slices/LoginSlice'
import EnrollSlice from './slices/EnrollSlice';
import rewardSlice from './slices/RewardSlice';
import taskSlice from './slices/TaskSlice';
import quizSlice from './slices/QuizSlice';
import courseSlice from './slices/CourseSlice';
import assignTaskSlice from './slices/AssignTaskSlice';
import profileSlice from './slices/ProfileSlice';

// refresh state
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { combineReducers } from '@reduxjs/toolkit';


const persistConfig = {
    key: 'root',
    version:1,
    storage,
  };

const reducer = combineReducers({
    user:userReducer,
    enrollStudent:EnrollSlice,
    rewards:rewardSlice,
    task:taskSlice,
    assignTask:assignTaskSlice,
    quiz:quizSlice,
    course:courseSlice,
    profile:profileSlice
})

const persistedReducer = persistReducer(persistConfig,reducer)

export default configureStore({
    // reducer:{
    //     user:userReducer,
    //     enrollStudent:EnrollSlice,
    //     rewards:rewardSlice,
    //     task:taskSlice,
    //     assignTask:assignTaskSlice,
    //     quiz:quizSlice,
    //     course:courseSlice
    // }
    reducer:persistedReducer
})