
import Card from "@mui/material/Card";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import GradientBorder from "examples/GradientBorder";

// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import borders from "assets/theme/base/borders";
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import Grid from '@mui/material/Grid';
import { Box, TextField } from "@mui/material";
import { useState } from "react";
import axios from "axios";
import { API_Host } from "Api_utils";
import { useDispatch, useSelector } from "react-redux";
import { addCourses, updateCourses } from "../../redux/slices/CourseSlice";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";


function UpdateCourses() {
  
  

  const dispatch = useDispatch();
  const courseUpdate = useSelector(state=> state.course.course);
  const {id} = useParams();
  const course = courseUpdate.find(courseData => courseData.id === id);
  console.log(course);
  const history = useHistory();

  
  const [coursesUpdate,setCoursesUpdate] = useState({
  
    courseName:course ? course.courseName:"",
    courseDescription:course ? course.courseDescription:"",
    courseInstructor:course ? course.courseInstructor:"",
    courseDuration:course ? course.courseDuration:""
  });

  const [courseerror,setCourseError] = useState({});

  

  // update call to update courses

  const handleUpdateCourses = async(e) => {
    e.preventDefault();

    const isValid = await courseValidate()
    if(!isValid){
     console.log("validate error");
     return;
    }
    try{
      axios.put(`${API_Host}/edit-course/${id}`,coursesUpdate)
      .then(res =>{
        dispatch(updateCourses(res.data))
        console.log("Courses updated successfully",res.data);

        Swal.fire({
          title: "Course Edited Successfully!",
          text: "Your course has been Edited.",
          icon: "success",
          showClass: {
            popup: `
              animate__animated
              animate__fadeInUp
              animate__faster
            `
          },
          hideClass: {
            popup: `
              animate__animated
              animate__fadeOutDown
              animate__faster
            `
          }
        });
        history.push('/courses');
      })
     
    }
    catch (err) {
      console.log("Error while updating courses:", err);
    }
  }



    const handleChangeCourse = (event) => {
      const {name,value} = event.target
      setCoursesUpdate((prevFormData) => ({
        ...prevFormData,
        [name]:value,
      }));
      const error = {...courseerror};
      if(value.trim() === ""){
      error[name] =``;
      
      }
      else{
        delete error[name]
      }
      setCourseError(error)
        };

        const courseValidate = async() =>{
          const error = {}
          if(!coursesUpdate.courseName) error.courseName = "coursename is required"
          if(!coursesUpdate.courseDescription) error.courseDescription = "Description is required"
          if(!coursesUpdate.courseInstructor) error.courseInstructor = "Instructor name is required"
          if(!coursesUpdate.courseDuration) error.courseDuration = "courseduration is required"
      
          setCourseError(error)
          return Object.keys(error).length === 0;
        }

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Box sx={{ width: '100%',height:'70vh' }} mt={10} >
      <VuiBox component="form" role="form" onSubmit={handleUpdateCourses}>
        {/* first col */}
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid  xs={2} sm={4} md={6}>
        <VuiBox mb={1} ml={2}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              Course Name
            </VuiTypography>
            <GradientBorder
            minWidth="100%"
            padding="1px"
            borderRadius={borders.borderRadius.lg}
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
            <VuiInput type="text" 
            placeholder="Enter Courses..." 
            fontWeight="500"
            name="courseName"
            value={coursesUpdate.courseName}
            onChange={handleChangeCourse} 
    
            error = {!!courseerror.courseName}
             />
          </GradientBorder>
          {courseerror.courseName && (
    <VuiTypography variant="caption" color="error" mt={1}>
      {courseerror.courseName}
    </VuiTypography>
  )}
        </VuiBox>
        </Grid>
{/* end */}

{/* second col */}
        <Grid xs={2} sm={4} md={6}>
        <VuiBox mb={1} ml={2}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
            Course Description
            </VuiTypography>
            <GradientBorder
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
             <VuiInput
              type="textarea"
              multiline rows={3}
              placeholder="Enter Course Description..."
              name="courseDescription"
              value={coursesUpdate.courseDescription}
              onChange={handleChangeCourse}
              error = {!!courseerror.courseDescription}
             
              sx={({ typography: { size ,white} }) => ({
                fontSize: size.sm,
                color:white
              })}
            />


          </GradientBorder>
          {courseerror.courseDescription && (
    <VuiTypography variant="caption" color="error" mt={1}>
      {courseerror.courseDescription}
    </VuiTypography>
  )}
          </VuiBox>
        </Grid>
       {/* end */}

       {/* third col */}

       <Grid xs={2} sm={4} md={6}>
        <VuiBox mb={1} ml={2}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              Course Instructor
            </VuiTypography>
            <GradientBorder
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
             <VuiInput
              type="text"
              placeholder="Enter Course Instructor..."
              name="courseInstructor"
              value={coursesUpdate.courseInstructor}
              onChange={handleChangeCourse}
              error = {!!courseerror.courseInstructor}
              sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })}
            />
          </GradientBorder>
          {courseerror.courseInstructor && (
    <VuiTypography variant="caption" color="error" mt={1}>
      {courseerror.courseInstructor}
    </VuiTypography>
  )}
          </VuiBox>
        </Grid>
       {/* end */}

       {/* fourth col */}
       <Grid xs={2} sm={4} md={6}>
        <VuiBox mb={1} ml={2}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              Course Duration
            </VuiTypography>
            <GradientBorder
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
             <VuiInput
              type="text"
              placeholder="Enter Duration..."
              name="courseDuration"
              value={coursesUpdate.courseDuration}
              onChange={handleChangeCourse}
              error = {!!courseerror.courseDuration}
              sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })}
            />
          </GradientBorder>
          {courseerror.courseDuration && (
    <VuiTypography variant="caption" color="error" mt={1}>
      {courseerror.courseDuration}
    </VuiTypography>
  )}
          </VuiBox>
        </Grid>
       {/* end */}

       {/* fifth col */}
       {/* <Grid xs={2} sm={4} md={4}>
        <VuiBox mb={1} ml={2}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              Instructor
            </VuiTypography>
            <GradientBorder
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
             <VuiInput
              type="text"
              placeholder="Search Instructor..."
              sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })}
            />
          </GradientBorder>
          </VuiBox>
        </Grid> */}
       {/* end */}


       {/* sixth col */}
       {/* <Grid xs={2} sm={4} md={4}>
        <VuiBox mb={1} ml={2}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              Category
            </VuiTypography>
            <GradientBorder
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
             <VuiInput
              type="text"
              placeholder="Category..."
              sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })}
            />
          </GradientBorder>
          </VuiBox>
        </Grid> */}
       {/* end */}

       

       {/* <Grid xs={2} sm={4} md={4} >
        <VuiBox mb={1} ml={2}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              Status
            </VuiTypography>
            <GradientBorder
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
             <VuiInput
              type="text"
              placeholder="Status..."
              sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })}
            />
          </GradientBorder>
          </VuiBox>
        </Grid> */}


        {/* submit */}

        
      </Grid>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} justifyContent="center">
  <Grid item xs={12} sm={8} md={4}>
    <VuiBox mt={5} mb={1}>
      <VuiButton color="info" fullWidth type="submit">
        SUBMIT
      </VuiButton>
    </VuiBox>
  </Grid>
</Grid>
</VuiBox>
    </Box>



     
      <Footer />
    </DashboardLayout>
  );
}

export default UpdateCourses;
