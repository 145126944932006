import { createSlice } from "@reduxjs/toolkit";
import { getReward } from "./RewardSlice";

const enrollstudentSlice = createSlice({
  name: "enrollStudent",
  initialState: {
    enrollStudent: [],
    studentCount: 0,
    instructorCount:0,
    // rewardPoints:0
  },
  reducers: {
    addEnrollStudent: (state, action) => {
      state.enrollStudent.push(action.payload);
    },
    getEnrollStudent: (state, action) => {
      state.enrollStudent = action.payload.map((student) => {
        return {
          id: student._id,
          name: student.name,
          password: student.password,
          email: student.email,
          phone: student.phone,
          language: student.language,
          course: student.course,
          role: student.role,
          freelancer: student.freelancer,
          rewardPoints: student.rewardPoints,
          profile:student.profile,
          githubUrl:student.githubUrl
        };
      });
      console.log(state.enrollStudent);
    },

    deleteEnrollStudent: (state, action) => {
      const id = action.payload.id;
      state.enrollStudent = state.enrollStudent.filter((student) => student.id !== id);
    },
    updateEnrollStudent: (state, action) => {
      const index = state.enrollStudent.findIndex((student) => student.id === action.payload);
      state.enrollStudent[index] = {
        id: action.payload.id,
        name: action.payload.name,
        email: action.payload.email,
        phone: action.payload.phone,
        password: action.payload.password,
        role: action.payload.role,
        language: action.payload.language,
        course: action.payload.course,
        rewardPoints: action.payload.rewardPoints,
        freelancer: action.payload.freelancer,
       profile:action.payload.profile,
       githubUrl:action.payload.githubUrl
      };
    },
    getStudentCount: (state, action) => {
      state.studentCount = action.payload;
    },
    getInstructorCount: (state, action) => {
        state.instructorCount = action.payload;
      },
      // getRewardCount: (state, action) => {
      //   console.log("Setting reward points in state:", action.payload);
      //   state.rewardPoints = action.payload;
      // },
  },
});

export const {
  addEnrollStudent,
  getEnrollStudent,
  deleteEnrollStudent,
  updateEnrollStudent,
  getStudentCount,
  getInstructorCount,
  // getRewardCount
} = enrollstudentSlice.actions;
export default enrollstudentSlice.reducer;
