
import { createSlice } from "@reduxjs/toolkit";

const profileSlice = createSlice({
    name:"profile",
    initialState:{
        profile:[]
    },
    reducers:{
        addProfile:(state,action) => {
            state.profile.push(action.payload);
        },
        

    }

})

export const {addProfile} = profileSlice.actions;
export default profileSlice.reducer;
