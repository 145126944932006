import { Quiz } from "@mui/icons-material";
import { createSlice } from "@reduxjs/toolkit";

const quizSlice = createSlice({
    name:Quiz,
    initialState:{
        quiz:[],
        quizCount:0
    },
    reducers:{
        addQuiz:(state,action) => {
            state.quiz.push(action.payload);
        },
        getQuiz:(state,action) => {
            state.quiz = action.payload.map(quizData => {
                return{
                    id:quizData._id,
                    topic:quizData.topic,
                    questions:quizData.questions,
                    attendedBy: quizData.attendedBy,
                    assignedBy:quizData.assignedBy
                    // options:quizData.options,
                    // correctAnswer:quizData.correctAnswer
                    // fileData:quizData.fileName
                }
            })
        },
        getAssignQuiz:(state,action) => {
            state.quiz = action.payload.map(quizData => {
                return{
                    id:quizData._id,
                    topic:quizData.topic,
                    questions:quizData.questions,
                    attendedBy: quizData.attendedBy,
                    assignedBy:quizData.assignedBy
                    // options:quizData.options,
                    // correctAnswer:quizData.correctAnswer
                    // fileData:quizData.fileName
                }
            })
        },
        deleteQuiz:(state,action) => {
            const id =action.payload;
            state.quiz =state.quiz.filter(quizData => quizData.id !==id)
        },
        updateQuiz:(state,action) => {
            const index = state.quiz.findIndex(quizData => quizData.id === action.payload)
            state.quiz[index] = {
                id:action.payload.id,
                topic:action.payload.topic,
                quizType:action.payload.quizType,
                questions:action.payload.questions,
                // options:action.payload.options,
                // correctAnswer:action.payload.correctAnswer
                // fileData: action.payload.fileData

            }
        },
        deleteQuizQuestion:(state,action) => {
            const questionId =action.payload;
            state.quiz =state.quiz.filter(quizData => quizData._id !==questionId)
        },
        getQuizCount: (state, action) => {
            state.quizCount = action.payload; 
          }
    }

})

export const {addQuiz,getQuiz,deleteQuiz,updateQuiz,deleteQuizQuestion,getQuizCount,getAssignQuiz} = quizSlice.actions;
export default quizSlice.reducer;
