import * as React from "react";
import Card from "@mui/material/Card";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import GradientBorder from "examples/GradientBorder";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import borders from "assets/theme/base/borders";
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import Grid from "@mui/material/Grid";
import { Box, MenuItem, Select } from "@mui/material";
import { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { API_Host } from "Api_utils";

function AddQuiz() {
  const history = useHistory();
  const [quizData, setQuizData] = useState({
    topicName: "",
    quizFile: null,
    assignedBy: [],
  });

  const [quizerror, setQuizError] = useState({});
  const [courseName, setCourseName] = useState([]);
  // const course = localStorage.getItem("course");
  // console.log(course)
  React.useEffect(() => {
    const fetchCourseName = async () => {
      try {
        const response = await axios.get(`${API_Host}/get-course`);
        setCourseName(response.data.data);
      } catch (error) {
        console.error("Error fetching coursename:", error);
      }
    };

    fetchCourseName();
  }, []);

  // const handleChange = (event) => {
  //   const { name, value, files } = event.target;
  //   setQuizData((prevFormData) => ({
  //     ...prevFormData,
  //     [name]: files ? files[0] : value, // Capture file if it's a file input
  //   }));

  //   const validation = { ...quizerror };
  //   if (value.trim() === "") {
  //     validation[name] = `${name} is required`;
  //   } else {
  //     delete validation[name];
  //   }
  //   setQuizError(validation);
  // };

  const handleChange = (event) => {
    const { name, value, files } = event.target;

    if (name === "assignedBy") {
      setQuizData((prevFormData) => ({
        ...prevFormData,
        [name]: value, // `value` is an array when using multiple select
      }));
    } else {
      setQuizData((prevFormData) => ({
        ...prevFormData,
        [name]: files ? files[0] : value, // For other fields, continue as is
      }));
    }

    // const validation = { ...quizerror };
    // if (value.trim() === "") {
    //   validation[name] = `${name} is required`;
    // } else {
    //   delete validation[name];
    // }
    // setQuizError(validation);
  };

  const handleQuizSubmit = async (e) => {
    e.preventDefault();

    const handleValidateQuiz = quizValidate();
    if (!handleValidateQuiz) {
      return;
    }

    const formData = new FormData();
    formData.append("topic", quizData.topicName);
    formData.append("quiz-file", quizData.quizFile);
    formData.append("assignedBy", quizData.assignedBy);

    try {
      const response = await axios.post(`${API_Host}/add-quiz`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      Swal.fire("Success", "Quiz uploaded successfully!", "success");
      history.push("/quiz");
    } catch (error) {
      console.error("Error uploading quiz:", error);
      Swal.fire("Error", "Something went wrong!", "error");
    }
  };

  const quizValidate = () => {
    const validation = {};
    if (!quizData.topicName) validation.topicName = "Topic name is required";
    if (!quizData.quizFile) validation.quizFile = "Quiz file is required";
    setQuizError(validation);
    return Object.keys(validation).length === 0;
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box sx={{ width: "100%", height: "100vh" }} mt={15}>
        <VuiBox component="form" role="form" onSubmit={handleQuizSubmit}>
          <Grid container spacing={3} columns={12}>
            {/* Topic Name */}
            <Grid xs={12} md={6}>
              <VuiBox mb={2} ml={2}>
                <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
                  Topic Name
                </VuiTypography>
                <GradientBorder
                  minWidth="100%"
                  padding="1px"
                  borderRadius={borders.borderRadius.lg}
                  backgroundImage={radialGradient(
                    palette.gradients.borderLight.main,
                    palette.gradients.borderLight.state,
                    palette.gradients.borderLight.angle
                  )}
                >
                  <VuiInput
                    type="text"
                    placeholder="Enter Topic Name"
                    name="topicName"
                    value={quizData.topicName}
                    onChange={handleChange}
                    sx={{ fontSize: "14px" }}
                  />
                </GradientBorder>
                {quizerror.topicName && (
                  <VuiTypography variant="caption" color="error">
                    {quizerror.topicName}
                  </VuiTypography>
                )}
              </VuiBox>
            </Grid>

            {/* File Upload */}
            <Grid xs={12} md={6}>
              <VuiBox mb={2} ml={2}>
                <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
                  Upload Quiz (Excel)
                </VuiTypography>
                <GradientBorder
                  minWidth="100%"
                  padding="1px"
                  borderRadius={borders.borderRadius.lg}
                  backgroundImage={radialGradient(
                    palette.gradients.borderLight.main,
                    palette.gradients.borderLight.state,
                    palette.gradients.borderLight.angle
                  )}
                >
                  <VuiInput
                    type="file"
                    name="quizFile"
                    onChange={handleChange}
                    sx={{ fontSize: "14px" }}
                  />
                </GradientBorder>
                {quizerror.quizFile && (
                  <VuiTypography variant="caption" color="error">
                    {quizerror.quizFile}
                  </VuiTypography>
                )}
              </VuiBox>
            </Grid>
            <Grid item xs={12} sm={6}>
              <VuiBox mb={1}>
                <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
                  Assign Quiz
                </VuiTypography>
                <GradientBorder
                  minWidth="100%"
                  padding="1px"
                  borderRadius={borders.borderRadius.lg}
                  backgroundImage={radialGradient(
                    palette.gradients.borderLight.main,
                    palette.gradients.borderLight.state,
                    palette.gradients.borderLight.angle
                  )}
                >
                  <Select
                    sx={{
                      backgroundColor: "#0f1535 !important",
                      color: "white !important",
                      borderRadius: "0.975rem !important",
                      display: "flex",
                      flexWrap: "wrap", 
                      width: "100%", 
                    }}
                
                    name="assignedBy"
                    value={quizData.assignedBy} 
                    onChange={handleChange}
                    // error={!!taskError.assigned}
                    displayEmpty
            
                  >
                    <MenuItem value="" disabled>
                      Select Course
                    </MenuItem>
                    {courseName.map((course, index) => (
                      <MenuItem key={index} value={course.courseName}>
                        {course.courseName}
                      </MenuItem>
                    ))}
                  </Select>
                </GradientBorder>
                {/* {taskError.assigned && (
                  <VuiTypography variant="caption" color="error" mt={1}>
                    {taskError.assigned}
                  </VuiTypography>
                )} */}
              </VuiBox>
            </Grid>
          </Grid>

          <Grid container justifyContent="center">
            <Grid item xs={12} sm={6} md={4}>
              <VuiButton color="info" fullWidth type="submit">
                Submit
              </VuiButton>
            </Grid>
          </Grid>
        </VuiBox>
      </Box>
      <Footer />
    </DashboardLayout>
  );
}

export default AddQuiz;
