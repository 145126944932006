import { createSlice } from "@reduxjs/toolkit";

const courseSlice = createSlice({
    name:"course",
    initialState:{
        course:[],
        courseCount:0,
    },
reducers:{
    addCourses:(state,action) => {
        state.course.push(action.payload);
    },

    getCourses:(state,action) => {
        state.course = action.payload.map(courseData => {
            return{
                id:courseData._id,
                courseName:courseData.courseName,
                courseDescription:courseData.courseDescription,
                courseInstructor:courseData.courseInstructor,
                courseDuration:courseData.courseDuration
            }

        })
    },
    deleteCourses:(state,action) => {
        const id =action.payload.id;
        state.course = state.course.filter(courseData => courseData.id !== id)
    },
    updateCourses:(state,action) => {
        const index = state.course.findIndex(courseData => courseData.id === action.payload)
        state.course[index]={
            id: action.payload.id,
            courseName: action.payload.courseName,
            courseDescription: action.payload.courseDescription,
            courseInstructor: action.payload.courseInstructor,
            courseDuration: action.payload.courseDuration,

        }
    },
    getCoursesCount: (state, action) => {
        state.courseCount = action.payload; 
      }
}
})

export const {addCourses,getCourses,deleteCourses,updateCourses,getCoursesCount} = courseSlice.actions;
export default courseSlice.reducer;