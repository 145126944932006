import Card from "@mui/material/Card";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data

import { Link } from "react-router-dom";
import { Button, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_Host } from "Api_utils";
import { deleteCourses, getCourses } from "../../redux/slices/CourseSlice";
import VuiButton from "components/VuiButton";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import "../../../src/app.css";
import VuiInput from "components/VuiInput";
import { Margin } from "@mui/icons-material";

function ViewCourse() {
  const userRole = useSelector((state) => state.user.role); // Assuming role is stored in state.user.role

  const dispatch = useDispatch();
  const courseData = useSelector((state) => state.course.course);

  // filter data
  const [searchCourse, setSearchCourse] = useState("");

  const filteredCourseData = courseData.filter(
    (course) =>
     (course.courseName &&   course.courseName .toLowerCase().includes(searchCourse.toLowerCase())) ||
      (course.courseDescription && course.courseDescription.toLowerCase().includes(searchCourse.toLowerCase())) ||
      (course.courseInstructor && course.courseInstructor.toLowerCase().includes(searchCourse.toLowerCase()))
  );

  // get call
  const fetchCourse = async () => {
    try {
      const res = await axios.get(`${API_Host}/get-course`);
      dispatch(getCourses(res.data.data));
      console.log("fetch quiz data", res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchCourse();
  }, []);

  // delete api
  const handleDeleteCourse = (id) => {
    // Show confirmation dialog
    Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to delete this course? This action cannot be undone.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deleted!",
          text: "Your course has been deleted.",
          icon: "success",
        });
        axios
          .delete(`${API_Host}/delete-course/${id}`)
          .then((res) => {
            dispatch(deleteCourses({ id }));
            console.log("Course deleted successfully", res.data);
            fetchCourse();
          })
          .catch((err) => console.log(err));
      } else {
        // User canceled the deletion
        console.log("Deleted");
      }
    });
  };

  const column = [
    { name: "courseName", align: "center" },
    { name: "courseDescription", align: "center" },
    { name: "courseInstructor", align: "center" },
    { name: "courseDuration", align: "center" },
    { name: "edit", align: "center" },
    { name: "delete", align: "center" },
  ];

  const rows = filteredCourseData.map((courseData) => ({
    courseName: courseData.courseName,
    courseInstructor: courseData.courseInstructor,
    courseDuration: courseData.courseDuration,
    courseDescription: courseData.courseDescription,
    edit: (
      <>
        <Link to={`/update-course/${courseData.id}`}>
          {/* <Button style={{color:"white"}}>Edit</Button>  */}
          <EditIcon fontSize="small" className="editIcon"></EditIcon>
        </Link>
      </>
    ),
    delete: (
      <>
        {/* <Button  style={{color:"white"}} onClick={() => handleDeleteCourse(courseData.id)}>Delete</Button> */}
        <DeleteIcon
          onClick={() => handleDeleteCourse(courseData.id)}
          className="deleteIcon"
          fontSize="small"
        ></DeleteIcon>
      </>
    ),
  }));

  console.log(rows);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <VuiBox py={3}  mt={5} sx={{height:'100vh'}}>
        {/* <VuiBox mb={8}>
          
        </VuiBox> */}
        <Link to={`/add-courses`}>
          {/* <Button sx={{marginLeft:"10px"}}>AddQuiz</Button> */}
          {/* (userRole.) */}
          {/* <VuiButton sx={{marginBottom:"15px",marginLeft:"15px"}} variant="contained" color="info">Add Course</VuiButton> */}
        </Link>
        <>
          <VuiBox style={{ width: "25%", marginLeft: "20px" }}>
            <VuiInput
              placeholder="Type here..."
              icon={{ component: "search", direction: "left" }}
              sx={({ breakpoints }) => ({
                [breakpoints.down("sm")]: {
                  maxWidth: "80px",
                },
                [breakpoints.only("sm")]: {
                  maxWidth: "80px",
                },
                backgroundColor: "info.main !important",
              
              })}
              value={searchCourse}
              onChange={(e) => setSearchCourse(e.target.value)}
            />
          </VuiBox>
        </>
        <Card sx={{ mt: 3 }}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
              <VuiBox display="flex" justifyContent="space-between" alignItems="center">
                <VuiTypography
                  variant="lg"
                  color="white"
                  style={{ marginLeft: "10px", marginBottom: "15px" }}
                >
                  Courses
                </VuiTypography>
              </VuiBox>
            </Grid>
            <Grid item xs={6}>
              <div className="bttn">
                <Link to={`/add-courses`} className="link">
                  <VuiButton
                    sx={{ marginBottom: "18px", width: "150px" }}
                    variant="contained"
                    color="info"
                  >
                    <AddIcon style={{ marginRight: "10px" }}></AddIcon>
                    Add Course
                  </VuiButton>
                </Link>
              </div>
            </Grid>
          </Grid>

          <VuiBox
            sx={{
              "& th": {
                borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                  `${borderWidth[1]} solid ${grey[700]}`,
              },
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                    `${borderWidth[1]} solid ${grey[700]}`,
                },
              },
            }}
          >
            <Table columns={column} rows={rows} />
          </VuiBox>
        </Card>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ViewCourse;
