import * as React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Box, Card, Grid, Typography,Button } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { API_Host } from "Api_utils";
import VuiButton from "components/VuiButton";
// import Button from "assets/theme/components/button";

function QuizResult() {
  const { id } = useParams();
  const [quizResult, setQuizResult] = useState({
    topicName: "",
    questions: [{ question: "", options: [""], correctAnswer: "", selectedOption: "" }],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 3;
  // Fetch quiz result from server
  useEffect(() => {
    const fetchQuizResult = async () => {
      try {
        const response = await axios.get(`${API_Host}/get-result-quiz/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        setQuizResult(response.data.data);
        setLoading(false);
      } catch (err) {
        setError("Error fetching quiz results");
        setLoading(false);
      }
    };
    fetchQuizResult();
  }, [id]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  // pagination
  const startIndex = currentPage * itemsPerPage;
  const selectedQuestions = quizResult.questions.slice(startIndex, startIndex + itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < Math.ceil(quizResult.questions.length / itemsPerPage) - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box sx={{ width: "100%" }} mt={10} mb={34}>
        <Card>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h4" align="center" sx={{ color: "white !important" }}>
                Quiz Results: {quizResult.topicName}
              </Typography>
            </Grid>

            {selectedQuestions.map((question, index) => (
              <Grid item xs={12} key={index}>
                <Card sx={{ padding: "20px", marginBottom: "20px" }}>
                  <Typography variant="h6" sx={{ color: "white !important" }}>
                    Question {startIndex + index + 1}: {question.question}
                  </Typography>
                  <Box mt={2}>
                    {question.options.map((option, idx) => (
                      <Typography
                        key={idx}
                        style={{
                          fontWeight: question.selectedOption === option ? "bold" : "normal",
                          color:
                            question.correctAnswer === option
                              ? "green" // Correct answer
                              : question.selectedOption === option
                              ? "red" // Selected option (incorrect)
                              : "white", // Neutral
                        }}
                      >
                        {idx + 1}. {option}
                      </Typography>
                    ))}
                    {question.selectedOption !== question.correctAnswer && (
                      <Typography style={{ color: "blue", marginTop: "10px" }}>
                        Correct Answer: {question.correctAnswer}
                      </Typography>
                    )}
                  </Box>
                </Card>
              </Grid>
            ))}
           <Grid item xs={12} display="flex" justifyContent="center">
              <VuiButton color='info'
                onClick={handlePreviousPage}
                disabled={currentPage === 0}
                sx={{ marginRight: "10px" }}
              >
                Previous
              </VuiButton>
              <VuiButton color='info'
                onClick={handleNextPage}
                disabled={currentPage === Math.ceil(quizResult.questions.length / itemsPerPage) - 1}
              >
                Next
              </VuiButton>
            </Grid>
          </Grid>
        </Card>
      </Box>
      <Footer />
    </DashboardLayout>
  );
}

export default QuizResult;
