import { Task, UploadFile } from "@mui/icons-material";
import { createSlice } from "@reduxjs/toolkit";

const taskSlice = createSlice({
    name:Task,
    initialState:{
        task:[]
    },
    reducers:{
        addTask:(state,action) => {
            state.task.push(action.payload);
        },
        getTask:(state,action) => {
            state.task = action.payload.map(taskData => {
                return{
                    id:taskData._id,
                    taskName:taskData.taskName,
                    status:taskData.status,
                    taskType:taskData.taskType,
                    fileData:taskData.fileName
                }
            })
        },
        deleteTask:(state,action) => {
            const id =action.payload;
            state.task =state.task.filter(taskData => taskData.id !==id)
        },
        updateTask:(state,action) => {
            const index = state.task.findIndex(taskData => taskData.id === action.payload)
            state.task[index] = {
                id:action.payload.id,
                taskName:action.payload.taskName,
                taskType:action.payload.taskType,
                fileData:action.payload.fileName

            }
        },
        updateSubmitTask:(state,action) => {
            const index = state.task.findIndex(taskData => taskData.id === action.payload)
            state.task[index] = {
                id:action.payload.id,
                status:action.payload.status,
              

            }
        
        }

    }

})

export const {addTask,getTask,deleteTask,updateTask,updateSubmitTask} = taskSlice.actions;
export default taskSlice.reducer;
