import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  Grid,
  Card,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

// Custom Components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import VuiButton from "components/VuiButton";

// Data & Redux Actions
import { API_Host } from "Api_utils";
import { getTask, deleteTask } from "../../redux/slices/TaskSlice";
import "../../../src/app.css";
import {
  deleteAssignTask,
  getAllAssignTask,
  getAssignTask,
} from "../../redux/slices/AssignTaskSlice";

import GradientBorder from "examples/GradientBorder";
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import VuiInput from "components/VuiInput";

function ViewAssignedTask() {
  const dispatch = useDispatch();
  const taskData = useSelector((state) => state.assignTask.assignTask);
  const test = useSelector((state) => state.assignTask.assignTask);

  const taskDataInstructor = useSelector((state) => state.assignTask.allAssignTask);

  // console.log(taskDataInstructor[0].assigned);

  console.log(test);
  const userRole = localStorage.getItem("role");
  const token = localStorage.getItem("token");

  const [fileNames, setFileNames] = useState({});
  const [uploadedFiles, setUploadedFiles] = useState({}); // To store uploaded file data
  const [fileOrLink, setFileOrLink] = useState({});
  const [linkInputs, setLinkInputs] = useState({});
  console.log("Component Rendered"); // Track renders in your console

  // filter data
  const [searchStudentTask, setStudentTask] = useState("");

  const filteredStudentTask = taskData.filter(
    (task) => task.taskName && task.taskName.toLowerCase().includes(searchStudentTask.toLowerCase())
  );

  // Handle link input change and update state for each task
  const handleLinkChange = (event, taskId) => {
    const { value } = event.target;
    setLinkInputs((prev) => ({
      ...prev,
      [taskId]: value, // Store the link value
    }));
  };

  const handleFileOrLinkChange = (event, taskId) => {
    const { value } = event.target;
    setFileOrLink((prev) => ({
      ...prev,
      [taskId]: value,
    }));
  };
  // Fetch tasks from API
  useEffect(() => {
    const fetchSubmittedTask = async () => {
      try {
        const res = await axios.get(`${API_Host}/getAssignedTaskFor-student`, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
          },
        });
        dispatch(getAssignTask(res.data.data));
        console.log(res.data.data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchSubmittedTask();
  }, []);

  const fetchTask = async () => {
    try {
      const res = await axios.get(`${API_Host}/getAssigned-task`, {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header
        },
      });
      dispatch(getAllAssignTask(res.data.data));
      console.log(res.data.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchTask();
  }, []);

  // Delete task handler
  const handleDeleteTask = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to delete this Task? This action cannot be undone.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((taskResult) => {
      if (taskResult.isConfirmed) {
        Swal.fire("Deleted!", "Your AssignTask has been deleted.", "success");

        axios.delete(`${API_Host}/delete-assign-task/${id}`).then(() => {
          dispatch(deleteAssignTask({ id }));
          fetchTask();
        });
      }
    });
  };

  // Handle file upload and update state for each task
  const handleFileChange = (event, taskId) => {
    const file = event.target.files[0];
    if (!file) return;

    // Update the fileNames state for the specific taskId
    setFileNames((prev) => ({
      ...prev,
      [taskId]: file.name, // Update only the corresponding task's file name
    }));
    console.log(taskId);

    setUploadedFiles((prev) => ({
      ...prev,
      [taskId]: file, // Store the actual file
    }));
  };
  console.log(fileNames);

  // Handle the submit action for a specific task
  // const handleSubmit = async (taskId, taskName) => {
  //   if (!uploadedFiles[taskId]) {
  //     Swal.fire("Error", "Please upload a file before submitting.", "error");
  //     return;
  //   }
  //   const token = localStorage.getItem("token"); // Retrieve the token from local storage
  //   console.log(token);

  //   const formData = new FormData();
  //   formData.append("studentFile", uploadedFiles[taskId]);
  //   // formData.append("taskId", taskId); // If other fields
  //   // formData.append("taskName",taskName)
  //   // // like taskName are required, append them too
  //   // formData.append("status",status);

  //   try {
  //     const response = await axios.put(
  //       `${API_Host}/submitAssignedTaskFor-student/${taskId}`,
  //       formData,
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //           Authorization: `Bearer ${token}`, // Include the token in the Authorization header
  //         },
  //       }
  //     );
  //     console.log("File uploaded successfully", response);
  //     Swal.fire("Success", "File submitted successfully", "success");
  //   } catch (error) {
  //     console.error("Error uploading file", error);
  //     Swal.fire("Error", "Failed to upload file", "error");
  //   }
  // };
  const handleSubmit = async (taskId, taskName) => {
    const token = localStorage.getItem("token"); // Retrieve the token from local storage

    const formData = new FormData();

    // Check if a file is uploaded
    if (uploadedFiles[taskId]) {
      formData.append("studentFile", uploadedFiles[taskId]);
      formData.append("fileOrLink", "file"); // Indicate that the submission is a file
    }
    // Check if a link is provided
    else if (linkInputs[taskId]) {
      formData.append("link", linkInputs[taskId]);
      formData.append("fileOrLink", "link"); // Indicate that the submission is a link
    } else {
      Swal.fire("Error", "Please upload a file or enter a link before submitting.", "error");
      return;
    }

    try {
      const response = await axios.put(
        `${API_Host}/submitAssignedTaskFor-student/${taskId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
          },
        }
      );
      console.log("Submission successful", response);
      Swal.fire("Success", "Your submission was successful!", "success");
      // Optionally, you might want to refresh the task list or handle any UI updates here
    } catch (error) {
      console.error("Error submitting task", error);
      Swal.fire("Error", "Failed to submit your task", "error");
    }
  };

  // Define table columns based on user role
  const studentColumn = [
    { name: "taskName", align: "center" },
    { name: "taskFile", align: "center" },
    { name: "status", align: "center" },
    { name: "submission", align: "center" },
    { name: "fileupload", align: "center" },
    { name: "upload", align: "center" },
    { name: "submit", align: "center" },
  ];

  const instructorColumn = [
    { name: "taskName", align: "center" },
    { name: "rewardpoints", align: "center" },
    { name: "status", align: "center" },
    { name: "submission", align: "center" },
    { name: "studentEmail", align: "center" },
    { name: "studentFile", align: "center" },
    { name: "edit", align: "center" },
    { name: "delete", align: "center" },
  ];

  const column = userRole === "student" ? studentColumn : instructorColumn;

  const rowStudent = filteredStudentTask.map((task) => {
    console.log(task);
    console.log("Task File:", task.taskFile);

    const currentDate = new Date();
    const submissionDate = new Date(task.submission);
    console.log(currentDate);
    console.log(submissionDate);

    // Check if the due date has passed
    // const isDueDateCrossed = currentDate > submissionDate;
    // const isDueDateCrossed = currentDate.toDateString() > submissionDate.toDateString();
    const currentDay = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate()
    );
    const submissionDay = new Date(
      submissionDate.getFullYear(),
      submissionDate.getMonth(),
      submissionDate.getDate()
    );
    const isDueDateCrossed = currentDay > submissionDay;

    console.log(isDueDateCrossed);

    let submissionMessage = task.submission;
    console.log(submissionMessage);
    let submissionColor = "white"; // Default color for submission date

    if (task.status === "complete") {
      submissionMessage = "Task Completed";
      submissionColor = "green"; // Color for completed tasks
    } else if (isDueDateCrossed) {
      submissionMessage = "Due date crossed";
      submissionColor = "red"; // Color for overdue tasks
    }

    const studentRows = {
      taskName: task.taskName,

      submission: <span style={{ color: submissionColor }}>{submissionMessage}</span>,

      taskFile: (
        <a
          style={{ color: "white" }}
          href={`http://portal1.appblocky.com/uploads/${task.taskFile}`}
          download="fileData"
        >
          {task.taskFile}
        </a>
        // <a href={`http://portal1.appblock.com/uploads/${task.taskFile}`} download="fileData">
        //   {task.taskFile}
        // </a>
      ),
      status: task.status,
      // fileupload: (
      //   <>
      //     <VuiBox mb={1} ml={2}>

      //       <GradientBorder
      //         minWidth="200%"
      //         borderRadius={borders.borderRadius.lg}
      //         padding="1px"
      //         backgroundImage={radialGradient(
      //           palette.gradients.borderLight.main,
      //           palette.gradients.borderLight.state,
      //           palette.gradients.borderLight.angle
      //         )}
      //       >
      //         {/* <VuiInput
      //         type="text"
      //         placeholder="Enter Type..."
      //         name='taskType'
      //         value={addTask.taskType}
      //         onChange={handleChange}
      //         sx={({ typography: { size } }) => ({
      //           fontSize: size.sm,
      //         })}
      //       /> */}

      //         <Select
      //           displayEmpty
      //           fullWidth
      //           sx={{
      //             backgroundColor: "#0f1535 !important",
      //             color: "white !important",
      //             borderRadius: "0.975rem !important",
      //           }}
      //         >
      //           <MenuItem value="" disabled>
      //             Select status
      //           </MenuItem>
      //         </Select>
      //       </GradientBorder>

      //     </VuiBox>
      //   </>
      // ),

      fileupload: (
        <>
          <VuiBox mb={1} ml={2} mt={2}>
            <GradientBorder
              minWidth="100%"
              borderRadius={borders.borderRadius.lg}
              padding="1px"
              backgroundImage={radialGradient(
                palette.gradients.borderLight.main,
                palette.gradients.borderLight.state,
                palette.gradients.borderLight.angle
              )}
            >
              {/* <VuiInput
                   type="text"
                   placeholder="Enter Type..."
                   name='taskType'
                   value={addTask.taskType}
                   onChange={handleChange}
                   sx={({ typography: { size } }) => ({
                     fontSize: size.sm,
                   })}
                 /> */}

              <Select
                value={fileOrLink[task.id] || ""}
                onChange={(e) => handleFileOrLinkChange(e, task.id)}
                label="Select"
                displayEmpty
                sx={{
                  backgroundColor: "#0f1535 !important",
                  color: "white !important",
                  borderRadius: "0.975rem !important",
                }}
              >
                <MenuItem value="" disabled>
                  Select file type
                </MenuItem>
                <MenuItem style={{ color: "blue" }} value="file">
                  File
                </MenuItem>
                <MenuItem style={{ color: "blue" }} value="link">
                  Link
                </MenuItem>
              </Select>
            </GradientBorder>
          </VuiBox>
        </>
      ),
      upload: (
        <>
          {fileOrLink[task.id] === "file" && (
            <>
              <input
                type="file"
                id={`file-upload-${task.id}`}
                style={{ display: "none" }}
                onChange={(e) => handleFileChange(e, task.id)}
              />
              <VuiButton
                variant="contained"
                color="info"
                onClick={() => document.getElementById(`file-upload-${task.id}`).click()}
                style={{ marginTop: "10px" }}
              >
                Upload File
              </VuiButton>
              {fileNames[task.id] && <div style={{ marginTop: "5px" }}>{fileNames[task.id]}</div>}
            </>
          )}

          {fileOrLink[task.id] === "link" && (
            <VuiInput
              type="text"
              placeholder="link here"
              style={{ marginTop: "10px", width: "70%" }}
              value={linkInputs[task.id] || ""}
              onChange={(e) => handleLinkChange(e, task.id)}
            />
          )}
        </>
      ),

      // upload: (
      //   <>
      //     <input
      //       type="file"
      //       id={`file-upload-${task.id}`}
      //       style={{ display: "none" }}
      //       onChange={(e) => handleFileChange(e, task.id)}
      //     />
      //     <VuiButton
      //       variant="contained"
      //       color="info"
      //       onClick={() => document.getElementById(`file-upload-${task.id}`).click()}
      //     >
      //       Upload
      //     </VuiButton>
      //     {/* Display uploaded file name only for this task */}
      //     {/* {fileNames[task._id] && (
      //       <div style={{ marginTop: "5px" }}>{fileNames[task._id]}</div>
      //     )} */}

      //     <p>{fileNames[task.id]}</p>
      //     {/* {
      //       (task.id === fileNames[task._id]) ? console.log("taskNAme") : console.log("error")

      //     } */}
      //     {/* {console.log(fileNames[task._id])} */}
      //     {/* <h1>{fileNames[task._id]}</h1> */}
      //   </>
      // ),
      submit: (
        <VuiButton
          variant="contained"
          color="success"
          onClick={() => handleSubmit(task.id, task.taskName)}
        >
          Submit
        </VuiButton>
      ),
    };
    console.log(task);
    return studentRows;
  });

  const rowsInstructor = taskDataInstructor.map((task) => {
    console.log(task);
    const instructorRows = {
      taskName: task.taskName,
      submission: task.submission,
      // taskType: task.taskType,
      rewardpoints: task.rewardPoints,
      status: task.status,

      studentEmail: task.assigned,
      // studentFile : task.taskFile,
      studentFile: (
        <>
          {/* <a href={`http://localhost:5000/uploads/${task.studentFile}`} download="fileData">
            <a href={`portal.appblocky.com/uploads/${task.studentFile}`} download="fileData">
            {task.studentFile}
          </a> */}
          {task.studentFile && task.studentFile.link && (
            <a style={{ color: "white" }} href={task.studentFile.link} target="_blank">
              {task.studentFile.link}
            </a>
          )}
          {task.studentFile && task.studentFile.fileName && (
            <span>
              <a
                style={{ color: "white" }}
                href={`http://portal1.appblocky.com/uploads/${task.studentFile.fileName}`}
                target="_blank"
                download
              >
                {task.studentFile.fileName}
              </a>
              {/* <a style = {{color:"white"}} href={`http://portal1.appblocky.com/uploads/${task.studentFile.fileName}`} target="_blank" download>
              {task.studentFile.fileName}
            </a> */}
            </span>
          )}
        </>
      ),
      //       linkFile:(
      // <a href={`${task.linkFile}`} download="fileData">
      //             {/* <a href={`portal.appblocky.com/uploads/${task.studentFile}`} download="fileData"> */}
      //             {task.linkFile}
      //           </a>
      //       ),
      edit: (
        <Link to={`/edit-assigned-task/${task.id}`}>
          <EditIcon fontSize="small" className="editIcon" />
        </Link>
      ),
      delete: (
        <DeleteIcon
          onClick={() => handleDeleteTask(task.id)}
          fontSize="small"
          className="deleteIcon"
        />
      ),
    };
    console.log(task);
    return instructorRows;
  });
  const rows = userRole === "student" ? rowStudent : rowsInstructor;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3} mt={8} sx={{ height: "100vh" }}>
        <Card>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
              <VuiBox display="flex" justifyContent="space-between" alignItems="center">
                <VuiTypography variant="lg" color="white" style={{ marginLeft: "10px" }}>
                  Task
                </VuiTypography>
              </VuiBox>
            </Grid>
            <Grid
              container
              item
              xs={6}
              justifyContent="flex-end"
              alignItems="center"
              marginBottom="20px"
            >
              {userRole !== "student" && (
                <Grid item>
                  <div className="bttn">
                    <Link to={`/assign-task`} className="link">
                      <VuiButton sx={{ marginBottom: "15px" }} variant="contained" color="info">
                        <AddIcon style={{ marginRight: "10px" }} />
                        Add Task
                      </VuiButton>
                    </Link>
                  </div>
                </Grid>
              )}

              <Grid item>
                <VuiBox style={{ width: "100%", maxWidth: "250px", paddingBottom: "15px" }}>
                  <VuiInput
                    placeholder="Type here..."
                    icon={{ component: "search", direction: "left" }}
                    sx={({ breakpoints }) => ({
                      [breakpoints.down("sm")]: {
                        maxWidth: "80px",
                      },
                      [breakpoints.only("sm")]: {
                        maxWidth: "80px",
                      },
                      backgroundColor: "info.main !important",
                    })}
                    value={searchStudentTask}
                    onChange={(e) => setStudentTask(e.target.value)}
                  />
                </VuiBox>
              </Grid>
            </Grid>
          </Grid>

          <VuiBox
            sx={{
              "& th": {
                borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                  `${borderWidth[1]} solid ${grey[700]}`,
              },
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                    `${borderWidth[1]} solid ${grey[700]}`,
                },
              },
            }}
          >
            {/* {
              userRole = 'student' ? <Table columns={column} rows={row} /> : <Table columns={column} rows={rowsInstructor} /> 

            } */}
            <Table columns={column} rows={rows} />
          </VuiBox>
        </Card>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ViewAssignedTask;
