import { createSlice } from "@reduxjs/toolkit";

const rewardSlice = createSlice({
    name:"rewards",
    initialState:{
        rewards:[]
    },
    reducers:{
        addReward:(state,action)=>{
            state.rewards.push(action.payload)
        },
        getReward:(state,action) => {
            state.rewards=action.payload.map(studentReward => {
                return{
                    id:studentReward._id,
                    rewardType:studentReward.rewardType,
                    points:studentReward.points

                }
            })
        },
        deleteReward:(state,action) => {
            const id =action.payload.id;
            state.rewards = state.rewards.filter(studentReward => studentReward.id !== id)
        },

        updateReward:(state,action) => {
            const index = state.rewards.findIndex(studentReward => studentReward.id === action.payload)
            state.rewards[index] = {
                id:action.payload.id,
                rewardType:action.payload.rewardType,
                point:action.payload.point

            }
        }
    }

})

export const  {addReward,getReward,deleteReward,updateReward} = rewardSlice.actions;
export default  rewardSlice.reducer;