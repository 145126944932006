/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// @mui material components
import Card from "@mui/material/Card";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

import { Button, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { column } from "stylis";
import { useDispatch, useSelector } from "react-redux";
import { getReward, deleteReward } from "../../redux/slices/RewardSlice";
import { useEffect } from "react";
import axios from "axios";
import { API_Host } from "Api_utils";
import VuiButton from "components/VuiButton";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import "../../../src/app.css";

function ViewRewards() {
  const studentReward = useSelector((state) => state.rewards.rewards);
  const dispatch = useDispatch();

  // get call
  useEffect(() => {
    const rewardData = async () => {
      try {
        const response = await axios.get(`${API_Host}/get-rewards`);
        dispatch(getReward(response.data.data));
        console.log("data fetched successfully", response.data.data);
      } catch (err) {
        console.log(err);
      }
    };
    rewardData();
  }, []);

  // delete call
  const handleDeleteReward = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to delete this Rewards? This action cannot be undone.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    })
      .then((quizResult) => {
        if (quizResult.isConfirmed) {
          Swal.fire({
            title: "Deleted!",
            text: "Your Rewards has been deleted.",
            icon: "success",
          });

          axios.delete(`${API_Host}/delete-rewards/` + id).then((res) => {
            dispatch(deleteReward({ id }));
          });
        }
      })

      .catch((err) => console.log(err));
  };

  const columns = [
    { name: "rewardtype", align: "center" },
    { name: "points", align: "center" },
    // {name:"action",align:"center"},
    { name: "edit", align: "center" },
    { name: "delete", align: "center" },
  ];

  const rows = studentReward.map((studentReward) => ({
    rewardtype: studentReward.rewardType,
    points: studentReward.points,
    edit: (
      <>
        <Link to={`/update-rewards/${studentReward.id}`}>
          {/* <Button>Edit</Button> */}
          <EditIcon fontSize="small" className="editIcon"></EditIcon>
        </Link>
      </>
    ),
    delete: (
      <>
        {/* <Button onClick={() =>handleDeleteReward(studentReward.id) }>Delete</Button> */}
        <DeleteIcon
          onClick={() => handleDeleteReward(studentReward.id)}
          className="deleteIcon"
          fontSize="small"
        ></DeleteIcon>
      </>
    ),
  }));
  return (
    <DashboardLayout>
      <DashboardNavbar />

      <VuiBox py={3} mb={30}>
        <VuiBox mb={8}></VuiBox>
        {/* <Link  to={`/add-rewards`} >
      <Button sx={{marginLeft:"15px"}}>AddRewards</Button>
      <VuiButton sx={{marginBottom:"15px",marginLeft:"15px"}} variant="contained" color="info">Add Rewards</VuiButton>
      </Link> */}

        <Card>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
              <VuiBox display="flex" justifyContent="space-between" alignItems="center">
                <VuiTypography variant="lg" color="white" style={{ marginLeft: "10px" }}>
                  Rewards
                </VuiTypography>
              </VuiBox>
            </Grid>
            <Grid item xs={6}>
              <div className="bttn">
                <Link to={`/add-rewards`} className="link">
                  <VuiButton sx={{ width: "160px" }} variant="contained" color="info">
                    <AddIcon style={{ marginRight: "10px" }}></AddIcon>
                    Add Rewards
                  </VuiButton>
                </Link>
              </div>
            </Grid>
          </Grid>

          <VuiBox
            sx={{
              "& th": {
                borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                  `${borderWidth[1]} solid ${grey[700]}`,
              },
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                    `${borderWidth[1]} solid ${grey[700]}`,
                },
              },
            }}
          >
            <Table columns={columns} rows={rows} />
          </VuiBox>
        </Card>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ViewRewards;
